import { useForm, Edit, useSelect, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Table,
    Select,
    Row,
    Col,
    Switch,
    InputNumber,
    Button,
    Popconfirm,
    message, Modal, Dropdown,
} from 'antd';

import {
    IAnno,
    IGruppoTaglie,
    IMisureRigheStd,
    IRigheStandardSchedeTaglie,
    IStagioni,
    IStatiSchedeTaglie
} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import { useCreate, useCreateMany, useList, useNavigation, useUpdate, useUpdateMany } from "@refinedev/core";
export const RigheStandardSchedaTaglieEdit: React.FC = () => {
    const [taglie, setTaglie] = useState<any>();
    const [showMisure, setShowMisure] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [righeM, setRigheM] = useState<any>([]);
    const [finish, setFinish] = useState(false);
    const { formProps, saveButtonProps, queryResult: resultRighe, id, formLoading, onFinish } = useForm<IRigheStandardSchedeTaglie>({
        meta: {
            populate: "rifGruppoTg, rifMisureRigheStds, rifMisureRigheStds.rifTaglie, rifTgBase, rifGruppoTg.rifTg",
        },
        warnWhenUnsavedChanges: false
    });

    const {selectProps: gruppoTaglieSelectProps, queryResult} = useSelect<IGruppoTaglie>({
        pagination: {
            pageSize: 99999
        },

        resource: 'gruppo-taglies',

        meta: {
            populate: '*'
        },

        optionValue: 'id',
        optionLabel: 'descGruppoTg',
        defaultValue: resultRighe?.data?.data.rifGruppoTg.id,

        sorters: [
            {
                field: 'rifTg][ordTg]',
                order: 'asc'
            }
        ]
    });



    useEffect(() => {
        setFinish(false);
        let col: any = [];
        const foundTg = resultRighe?.data?.data?.rifGruppoTg?.rifTg.map(item => ({
            label: item.descTg,
            value: item.id,
        }));

        // console.log(foundTg);
        // IMPOSTO ORDINAMENTO
        const righe: any = resultRighe?.data?.data?.rifMisureRigheStds?.sort((s1, s2) => {
            return s1.rifTaglie?.ordTg - s2.rifTaglie?.ordTg;
        });
        righe?.map( (item: any) => col.push({
            title: item.rifTaglie?.descTg,
            dataIndex: item.rifTaglie?.descTg,
            key: item.rifTaglie?.descTg,
            render: (value: any, record: any, index: number) => (
                <Input
                    defaultValue={value}
                    onPressEnter={(e: any) => handleChangeTg(item.rifTaglie?.descTg, e.target.value, index, record)} />
            ),
        }))
        col.push({
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (v: any, record: any) => {
                return <Button onClick={deleteRow} size={'middle'} style={{ color: 'red'}} icon={<Icons.DeleteOutlined />}  />
            }
        })
        let misureStd: any = [];
        misureStd.push(resultRighe?.data?.data?.rifMisureRigheStds.reduce(
            (obj, item: any) => Object.assign(obj, { [item.rifTaglie?.descTg]: item.misura }), {}));
        setTaglie(foundTg);
        setColumns(col);
        setRigheM((current: any) => [...misureStd])
        formProps.form?.setFieldsValue({'rifTgBase': resultRighe?.data?.data?.rifTgBase.id})
        // console.log(foundTg, col, misureStd)
    }, [formLoading, resultRighe?.data?.data])

    useEffect(() => {
        setFinish(true);
    }, [righeM]);

    interface DataType {
        id: string;
    }
    const updateMisureRigheStd = useUpdate<IMisureRigheStd>();
    const createRigheStdSchedeTaglie = useCreate<IRigheStandardSchedeTaglie>();
    const createMisureRigheStd = useCreateMany<IMisureRigheStd>();
    const [selectedRowRigheKeys, setSelectedRowRigheKeys] = useState<React.Key[]>([]);
    const selectedRowRighe = useRef<any>([]);
    const onSelectChangeRighe = (newSelectedRowRigheKeys: React.Key[], selectedRow: DataType[]) => {
        console.log('selectedRowRigheKeys changed: ', selectedRow);
        selectedRowRighe.current = selectedRow;
        setSelectedRowRigheKeys(newSelectedRowRigheKeys);
    };
    const rowSelectionRighe = {
        selectedRowRigheKeys,
        onChange: onSelectChangeRighe,
    };

    const { list, listUrl, editUrl, edit } = useNavigation();
    const checkRighe = useCreate();
    const modificaRighe = useCreate();
    const confermaModificaRighe = () => {
        console.log("Eseguo Modifica Misure su Righe Selezionate: ", selectedRowRighe.current);
        modificaRighe.mutateAsync({
                resource: 'modificaMisureRiga',
                successNotification: false,
                values: selectedRowRighe.current
            }
        ).then((res: any) => {
            console.log(res);
            if (res && res.data) {
                message.info('Righe Aggiornate Corretamente');
            }

        })

    }
    const cancelModalRighe = () => {
        console.log("Operazione Annullata");
    }

    const salvaAfterChangeTaglia = () => {
        delete righeM[0].Actions;
        Object.keys(righeM[0]).map( (k: any) => {
            // console.log(k);
            // console.log(resultRighe?.data?.data);
            const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === k);
            // console.log(fTg);
            updateMisureRigheStd.mutateAsync({
                resource: 'misure-righe-stds',
                successNotification: false,
                id: fTg.id,
                values: {
                    min: Number(righeM[0][k]) - Number(resultRighe?.data?.data.tolleranzaRigaStd),
                    max: Number(righeM[0][k]) + Number(resultRighe?.data?.data.tolleranzaRigaStd),
                    misura:Number(righeM[0][k])
                }
            }).then(() => {
                // message.info('Taglia Modificata Correttamente');
            })
        })
    }
    const confirm = () => {
        if (Number(formProps.form?.getFieldValue('versione')) === 0) {
            formProps.form?.setFieldsValue({'versione': 1});
            delete righeM[0].Actions;
            Object.keys(righeM[0]).map( (k: any) => {
                // console.log(k);
                // console.log(resultRighe?.data?.data);
                const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === k);
                // console.log(fTg);
                updateMisureRigheStd.mutateAsync({
                    resource: 'misure-righe-stds',
                    successNotification: false,
                    id: fTg.id,
                    values: {
                        min: Number(righeM[0][k]) - Number(resultRighe?.data?.data.tolleranzaRigaStd),
                        max: Number(righeM[0][k]) + Number(resultRighe?.data?.data.tolleranzaRigaStd),
                        misura:Number(righeM[0][k])
                    }
                }).then(() => {
                    // message.info('Taglia Modificata Correttamente');
                })
            })
            formProps.form?.submit();
        } else {
            if (selectedAnno.current && selectedStagione.current) {
                formProps.form?.setFieldsValue({'isBloccata': true});
                const versione = Number(formProps.form?.getFieldValue('versione'));
                // console.log(formProps.form?.getFieldsValue());
                console.log(righeM[0]);
                console.log(selectedAnno.current, selectedStagione.current);
                // console.log(resultRighe?.data?.data.rifMisureRigheStds)
                createRigheStdSchedeTaglie.mutateAsync({
                    resource: 'righe-std-scheda-taglies',
                    values: {
                        codiceBaseRigaStd: formProps.form?.getFieldValue('codiceBaseRigaStd'),
                        codiceRigaStd: formProps.form?.getFieldValue('codiceRigaStd'),
                        descRigaStd: formProps.form?.getFieldValue('descRigaStd'),
                        isBanda: formProps.form?.getFieldValue('isBanda'),
                        isTaglioRif: formProps.form?.getFieldValue('isTaglioRif'),
                        isTolleranzaPercentualeRigaStd: formProps.form?.getFieldValue('isTolleranzaPercentualeRigaStd'),
                        lBandaTgBase: formProps.form?.getFieldValue('lBandaTgBase'),
                        misuraTgBase: formProps.form?.getFieldValue('misuraTgBase'),
                        posizioneTgBase: formProps.form?.getFieldValue('posizioneTgBase'),
                        scalaTg: formProps.form?.getFieldValue('scalaTg'),
                        tolleranzaRigaStd: formProps.form?.getFieldValue('tolleranzaRigaStd'),
                        versione: -1,
                        rifGruppoTg: formProps.form?.getFieldValue('rifGruppoTg'),
                        rifTgBase: formProps.form?.getFieldValue('rifTgBase'),
                        isBloccata: false,

                    }
                }).then((resS: any) => {
                    delete righeM[0].Actions;
                    const misureRigheStd = Object.keys(righeM[0]).map(key => {
                        const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === key);
                        return {
                            rifRigheStdSchedaTg: resS.data.data.id,
                            rifTaglie: fTg.rifTaglie,
                            misura: Number(righeM[0][key]),
                            min: Number(righeM[0][key]) - Number(resultRighe?.data?.data.tolleranzaRigaStd),
                            max: Number(righeM[0][key]) + Number(resultRighe?.data?.data.tolleranzaRigaStd)
                        }
                    })
                    createMisureRigheStd.mutateAsync({
                        resource: 'misure-righe-stds',
                        successNotification: false,
                        values: misureRigheStd
                    }).then(() => {
                        edit('righe-std-scheda-taglies', resS.data.data.id);
                        console.log('OK:', resS.data.data.id);
                        // CONTROLLO ESISTENZA RIGA SU ALTRE SCHEDE
                        checkRighe.mutateAsync({
                            resource: 'checkRigheSchede',
                            successNotification: false,
                            values: {
                                anno: selectedAnno.current.toString(),
                                versioneRiga: versione,
                                codiceRiga: formProps.form?.getFieldValue('codiceRigaStd'),
                                stagioni: selectedStagione.current.toString()
                            }
                        }).then((res: any) => {
                            console.log(res);
                            if (res && res.data && res.data.length > 0) {
                                res.data.map((r: any) => (r.nuovoIdRigaStd = resS.data.data.id));
                                Modal.confirm({
                                    width: '70%',
                                    title: 'Su quali Schede vuoi modificare le misure?',
                                    content:
                                        <Table
                                            rowClassName={(record: any, index) => record.statoScheda === 'Aperta' ? 'green' :  ''}
                                            rowSelection={rowSelectionRighe}
                                            pagination={false}
                                            loading={res.data.length === 0}
                                            key={'key'}
                                            size={'small'}
                                            dataSource={res.data}>
                                            <Table.Column
                                                width={100}
                                                title={'Nome Scheda'}
                                                dataIndex={'nomeScheda'}
                                                key={'nomeScheda'}
                                            />
                                            <Table.Column
                                                width={100}
                                                ellipsis={true}
                                                title={'Stato Scheda'}
                                                dataIndex={'statoScheda'}
                                                key={'statoScheda'}
                                            />
                                            <Table.Column
                                                width={300}
                                                ellipsis={true}
                                                title={'Descrizione Scheda'}
                                                dataIndex={'descScheda'}
                                                key={'descScheda'}
                                            />
                                            <Table.Column
                                                width={300}
                                                ellipsis={true}
                                                title={'Misure Righe'}
                                                dataIndex={'misure'}
                                                key={'misure'}
                                            />
                                            <Table.Column
                                                width={150}
                                                title={'Codice Riga'}
                                                dataIndex={'codRiga'}
                                                key={'codRiga'}
                                            />
                                        </Table>,
                                    okText: 'Conferma',
                                    cancelText: 'Annulla',
                                    onOk: confermaModificaRighe,
                                    onCancel: cancelModalRighe
                                });
                            }
                        })
                        message.info('Taglie Aggiornate');
                        // const i = righeN.current[indexRigaM]['rifMisureRighes'].find((x: any) => x.rifTaglie.descTg === item.rifTaglie.descTg);
                        // console.log('MISURE NON MODIFICATE: ', righeN.current[indexRigaM]['rifMisureRighes']);
                        setTimeout(() => {
                            // setRigheM(righeN.current);
                            // window.location.reload();
                            // resultRighe?.refetch();
                        }, 500)


                    })
                    // list('righe-std-scheda-taglies');
                    // listUrl('anat/righe-std-scheda-taglies');
                })
            } else {
                message.error("Devi Selezionare Anno e Stagione per la ricerca su Schede Taglie!");
            }
        }
    };

    const cancel = () => {
        // formProps.form?.setFieldsValue({'isBloccata': false});
        // message.info('Clicked on No.');
        // delete righeM[0].Actions;
        // Object.keys(righeM[0]).map( (k: any) => {
        //     // console.log(k);
        //     // console.log(resultRighe?.data?.data);
        //     const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === k);
        //     // console.log(fTg);
        //     updateMisureRigheStd.mutateAsync({
        //         resource: 'misure-righe-stds',
        //         successNotification: false,
        //         id: fTg.id,
        //         values: {
        //             min: Number(righeM[0][k]) - Number(resultRighe?.data?.data.tolleranzaRigaStd),
        //             max: Number(righeM[0][k]) + Number(resultRighe?.data?.data.tolleranzaRigaStd),
        //             misura:Number(righeM[0][k])
        //         }
        //     }).then(() => {
        //         // message.info('Taglia Modificata Correttamente');
        //     })
        // })
        // console.log(formProps.form?.getFieldValue('versione'))
        // if (Number(formProps.form?.getFieldValue('versione')) === 0) {
        //     formProps.form?.setFieldsValue({'versione': 1});
        //     formProps.form?.submit();
        // }
        // listUrl('anat/righe-std-scheda-taglies');
    };

    const text = 'Verrà creata una nuova versione della riga, Confermi?';

    const deleteRow = () => {
        setRigheM((current: any) => [...[]])
    }

    const getMisure = (e: any) => {
        // console.log(e, taglie, formProps.form?.getFieldValue('misuraTgBase'));
        setRigheM((current: any) => [...[]]);
        setTimeout(() => {
            const tg = taglie.find((x: any) => x.value === e);
            // console.log(tg);
            if (formProps.form?.getFieldValue('misuraTgBase')) {
                // console.log(formProps.form?.getFieldValue('misuraTgBase'), e)
                const misuraTgBase = Number(formProps.form?.getFieldValue('misuraTgBase'));
                const scalaTg = Number(formProps.form?.getFieldValue('scalaTg'));
                const tagliaBase = taglie.find((x: any) => x.value === e);
                // console.log(e)
                // console.log(taglie)
                // console.log(tagliaBase)
                setShowMisure(true);
                let v = scalaTg;
                const indexBase = columns.findIndex((x: any) => x.key === tagliaBase?.label);
                // console.log(indexBase)
                const obj = Object.fromEntries(
                    columns.map((obj: any, i: number) => {
                        return [obj.title, (indexBase === i ? misuraTgBase
                            : i < indexBase ? Number(misuraTgBase - ((indexBase - i) * v)).toFixed(1)
                                : i > indexBase ? Number(misuraTgBase + ((i - indexBase) * v)).toFixed(1) : 0)]
                    })
                );
                setRigheM((current: any) => [obj])
            }
        }, 500)

    }


    const getTaglie = (data: any) => {
        setRigheM([]);
        const tg = queryResult.data?.data?.find((item => item.id === data));
        const foundTg = tg?.rifTg?.map(item => ({
            label: item.descTg,
            value: item.id,
        }));
        const col: any = tg?.rifTg?.map( item => ({
            title: item.descTg,
            dataIndex: item.descTg,
            key: item.descTg,
            render: (value: any, record: any, index: number) => {
                // console.log(record)
                return (
                    <Input defaultValue={value} onPressEnter={(e: any) => handleChangeTg(item.descTg, e.target.value, index, record)} />
                )
            }
        }))
        col.push({
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (v: any, record: any) => {
                return <Button onClick={deleteRow} size={'small'} style={{ color: 'red'}} icon={<Icons.DeleteOutlined />}  />
            }
        })
        setTaglie(foundTg);
        setColumns(col);

        setTimeout(() => {
            const isTagliaBase: any = tg?.rifTg.find((t: any) => t.isUnaTgBase);
            // console.log(isTagliaBase);
            formProps.form?.setFieldsValue({'rifTgBase': isTagliaBase.id})
        }, 500)
    }

    const handleChangeTg = (key: any, value: any, index: number, data: any) => {
        // console.log(key, value, index, data, righeM);
        data[key] = value;

        const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === key);
        // console.log(fTg);
        message.info('Taglia Modificata Correttamente');
    }

    function increment_alphanumeric_str(str: any){
        var numeric = str.match(/\d+$/)[0];
        var prefix = str.split(numeric)[0];

        function increment_string_num(str: any){
            var inc = String(parseInt(str)+1);
            return str.slice(0, str.length-inc.length)+inc;
        }

        return prefix+increment_string_num(numeric);
    }

    const { data: getRigheList } = useList<IRigheStandardSchedeTaglie>({
        resource: 'righe-std-scheda-taglies',

        pagination: {
            pageSize: 999999
        }
    });

    const clone = () => {
        //const data: any = formProps.form?.getFieldsValue();
        // const misureRigheStd = Object.keys(righeM[0]).map(key => {
        //     const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === key);
        //     return {
        //         rifRigheStdSchedaTg: null,
        //         rifTaglie: fTg.rifTaglie,
        //         misura: Number(righeM[0][key]),
        //         min: Number(righeM[0][key]) - Number(resultRighe?.data?.data.scalaTg),
        //         max: Number(righeM[0][key]) + Number(resultRighe?.data?.data.scalaTg)
        //     }
        // })
        // data.rifMisureRigheStds = misureRigheStd;
        //data.id = id;
        createRigheStdSchedeTaglie.mutateAsync({
            resource: 'clonaRigheStd',
            values: {id}
        }).then((res: any) => {
            console.log(res);
            edit('righe-std-scheda-taglies', res.data.data);
        })
    }

    const cloneOld = () => {
        // console.log(getRigheList);
        const maxCodice: any = getRigheList?.data.reduce((prev: any, current: any) => (Number(prev.codiceRigaStd.split('/')[0]) > Number(current.codiceRigaStd.split('/')[0])) ? prev : current)
        // console.log(maxCodice.codiceRigaStd);
        // console.log(formProps.form?.getFieldsValue());
        // console.log(righeM[0]);
        // console.log(resultRighe?.data?.data.rifMisureRigheStds);
        // INCREMENTO CODICE RIGA STD
        const codiceRiga = increment_alphanumeric_str(maxCodice.codiceRigaStd.split('/')[0]);
        const codiceRiga2 = formProps.form?.getFieldValue('codiceRigaStd').split('/')[1];
        // console.log(codiceRiga + '/' + codiceRiga2);
        const checkIfExist: any = getRigheList?.data.findIndex((x: any) => x.codiceRigaStd === codiceRiga + '/' + codiceRiga2);
        // console.log(checkIfExist);
        if (checkIfExist > -1) {
            message.warning('Attenzione Codice Esistente');
        } else {
            createRigheStdSchedeTaglie.mutateAsync({
                resource: 'righe-std-scheda-taglies',
                values: {
                    codiceBaseRigaStd: formProps.form?.getFieldValue('codiceBaseRigaStd'),
                    codiceRigaStd: codiceRiga + '/' + codiceRiga2,
                    descRigaStd: formProps.form?.getFieldValue('descRigaStd'),
                    isBanda: formProps.form?.getFieldValue('isBanda'),
                    isTaglioRif: formProps.form?.getFieldValue('isTaglioRif'),
                    isTolleranzaPercentualeRigaStd: formProps.form?.getFieldValue('isTolleranzaPercentualeRigaStd'),
                    lBandaTgBase: formProps.form?.getFieldValue('lBandaTgBase'),
                    misuraTgBase: formProps.form?.getFieldValue('misuraTgBase'),
                    posizioneTgBase: formProps.form?.getFieldValue('posizioneTgBase'),
                    scalaTg: formProps.form?.getFieldValue('scalaTg'),
                    tolleranzaRigaStd: formProps.form?.getFieldValue('tolleranzaRigaStd'),
                    versione: 1,
                    rifGruppoTg: formProps.form?.getFieldValue('rifGruppoTg'),
                    rifTgBase: formProps.form?.getFieldValue('rifTgBase'),
                    isBloccata: false,

                }
            }).then((res: any) => {
                const misureRigheStd = Object.keys(righeM[0]).map(key => {
                    const fTg: any = resultRighe?.data?.data.rifMisureRigheStds.find((d: any) => d.rifTaglie.descTg === key);
                    return {
                        rifRigheStdSchedaTg: res.data.data.id,
                        rifTaglie: fTg.rifTaglie,
                        misura: Number(righeM[0][key]),
                        min: Number(righeM[0][key]) - Number(resultRighe?.data?.data.tolleranzaRigaStd),
                        max: Number(righeM[0][key]) + Number(resultRighe?.data?.data.tolleranzaRigaStd)
                    }
                })
                createMisureRigheStd.mutateAsync({
                    resource: 'misure-righe-stds',
                    successNotification: false,
                    values: misureRigheStd
                }).then((result: any) => {
                    // console.log('OK: ', res);
                    edit('righe-std-scheda-taglies', res.data.data.id);
                })
                // list('righe-std-scheda-taglies');
            })
        }
    }
     const {selectProps: annoSelectProps} = useSelect<IAnno>({
        pagination: {
            pageSize: 100
        },
        resource: 'annis',
        meta: {
            populate: '*'
        },
        optionValue: 'anno',
        optionLabel: 'anno',
    });

    const {selectProps: stagioniSchedeSelectProps, queryResult: resultStagioni} = useSelect<IStagioni>({
        pagination: {
            pageSize: 100
        },
        resource: 'stagionis',
        meta: {
            populate: '*'
        },
        optionValue: 'nomeStagione',
        optionLabel: 'nomeStagione',
    });

    // console.log(resultStagioni.data?.data.filter((x: any) =>  x.isStagioneInCorso).map((d: any) =>  { return {value: d.nomeStagione, label: d.nomeStagione}}))


    const selectedStagione: any = useRef();
    const selectedAnno: any = useRef();
    return (
        <Edit saveButtonProps={saveButtonProps} breadcrumb={false} footerButtons={
            <>
                <Select {...annoSelectProps}
                    placeholder={'Seleziona Anno'}
                    style={{ width: 120 }}
                        onChange={(e: any) => {selectedAnno.current = e}}
                    allowClear
                />
                <Select {...stagioniSchedeSelectProps}
                        placeholder={'Seleziona Stagione'}
                        style={{ width: 120 }}
                        onChange={(e: any) => {selectedStagione.current = e}}
                        allowClear
                        // defaultValue={resultStagioni.data?.data.filter((x: any) =>  x.isStagioneInCorso).map((d: any) =>  { return {value: d.nomeStagione, label: d.nomeStagione}})}
                />
                <Button onClick={() => {list('righe-std-scheda-taglies')}} type={'primary'} style={{
                    backgroundColor: '#e85500',
                    border: 'none'
                }}>Annulla</Button>
                <Button onClick={clone} type={'primary'} style={{
                    backgroundColor: '#5b5a5a',
                    border: 'none'
                }}>Clona</Button>
                <Popconfirm
                    placement="top"
                    title={text}
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type={'primary'} htmlType={'button'}>Salva</Button>
                </Popconfirm>
            </>
        }>
            <Form {...formProps} layout="vertical" onFinish={(values: any) => {
                // console.log(values);
                values.descRigaStd = values.descRigaStd.toUpperCase();
                values.codiceRigaStd = values.codiceRigaStd.toUpperCase();
                onFinish(values);
            }}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item hidden={true}
                            label="Bloccato"
                            name="isBloccata"
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                        <Form.Item
                            label="Codice"
                            name="codiceRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input className={resultRighe?.data?.data.isUltimaVersione ? 'green': 'red'} style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Ver."
                            name="versione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Codice Base"
                            name="codiceBaseRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Descrizione"
                            name="descRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Tolleranza"
                            name="tolleranzaRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'} step="0.1"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Tolleranza %"
                            name="isTolleranzaPercentualeRigaStd"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Gruppo Taglie"
                            name={["rifGruppoTg", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...gruppoTaglieSelectProps} onSelect={(e) => getTaglie(e)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Misura Tg Base"
                            name="misuraTgBase"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'1'}
                                         style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Scala Tg - (Premere Invio)"
                            name="scalaTg"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'}
                                         step="0.1"
                                         style={{width: '100%'}} onPressEnter={(e) => getMisure(formProps.form?.getFieldValue('rifTgBase'))}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Taglia Base"
                            name="rifTgBase"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select options={taglie} onSelect={(e) => getMisure(e)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Banda"
                            name="isBanda"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Taglio Riferimento"
                            name="isTaglioRif"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Posizione Taglia Base"
                            name="posizioneTgBase"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'}
                                         step="0.1"
                                         style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Larghezza Taglia Base"
                            name="lBandaTgBase"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'}
                                         step="0.1"
                                         style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <hr/>
                <div>
                    { finish &&
                    <Table
                        pagination={false}
                        dataSource={righeM}
                        columns={columns}>
                    </Table>
                    }
                </div>
            </Form>
        </Edit>
    );
};
