import { List, TagField, useTable, EditButton, DeleteButton } from "@refinedev/antd";
import { Table, Space } from "antd";

import {IStagioni} from 'interfaces';
import React from 'react';

export const StagioniList: React.FC = () => {
    const { tableProps, setFilters } = useTable<IStagioni>({
        meta: {
            populate: "*",
        }
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
        [{
            field: 'user][id]',
            operator: 'eq',
            value: d
        }])
    }

    return (
        <List title={'Stagioni'} breadcrumb={false}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="nomeStagione"
                    title="Nome Stagione"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column<IStagioni>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
