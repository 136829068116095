import { useForm, Edit, useSelect } from "@refinedev/antd";
import { Form, Input, Table, Select, Switch } from "antd";
import {IGruppoTaglie, ITaglie} from 'interfaces';
import React, {useEffect} from 'react';

interface sedi {
    label: string;
    value: number;
}
export const TaglieEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm<ITaglie>({
        meta: {
            populate: "*",
        },
    });

    useEffect( () => {


    }, [formLoading])


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Taglia"
                    name="descTg"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ordinamento"
                    name={"ordTg"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Default Taglia Base"
                    name={["isUnaTgBase"]}
                    valuePropName={'checked'}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Edit>
    );
};
