import { useForm, Edit } from "@refinedev/antd";
import { Form, Input, Table, Switch } from "antd";
import React, {useEffect} from 'react';
import {IAnno} from '../../interfaces';

interface sedi {
    label: string;
    value: number;
}
export const AnnoEdit: React.FC = () => {
    const { formProps, saveButtonProps, formLoading } = useForm<IAnno>({
        meta: {
            populate: "*",
        },
    });


    useEffect( () => {

    }, [formLoading])


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Anno"
                    name="anno"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    );
};
