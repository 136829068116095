import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Table } from "antd";

import {IStagioni} from 'interfaces';
import React from 'react';

export const StagioniCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IStagioni>();
    const handleSubmit = (e: any) => {
        console.log(e);
        onFinish(e).then();
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Nome Stagione"
                    name="nomeStagione"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Riferimento Taglie"
                    name="rifSchedaTaglie"
                >
                    <Table />
                </Form.Item>
            </Form>
        </Create>
    );
};
