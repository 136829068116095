import {
    List,
    TagField,
    useTable,
    EditButton,
    DeleteButton,
    CreateButton,
    useSelect
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Table, Space, Form, Button, Input, Tooltip } from "antd";

import {IMisureRighe, IRigheSchedeTaglie, ISchedeTaglie, IStatiSchedeTaglie} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import {useCreate, useList, useNavigation, useUpdate} from '@refinedev/core';
import moment from 'moment';
import dayjs from 'dayjs';
import {
    StyleSheet,
} from "@react-pdf/renderer";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';

export const SchedeTaglieList: React.FC = () => {
    const {edit} = useNavigation();
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const permission =  utilityHelper.getTipo();
    const ditta =  utilityHelper.getDitta();
    const [loading, setLoading] = useState(false);
    // console.log(ditta);
    // console.log(permission);

    const { tableProps, setFilters, searchFormProps, tableQueryResult } = useTable<ISchedeTaglie>({
        resource: 'getSchedeTaglie',
        meta: {
            populate: "rifAnno, rifStagione, rifStatoSchedaTaglie",
            // populate: "rifAnno, rifDitta, foto, figurino, allegati, rifStagione, rifTgBase, rifStatoSchedaTaglie, rifStoriaSchedeTaglie, rifGruppoTaglie, rifGruppoTaglie.rifTg, rifRigheSchedaTg, rifRigheSchedaTg.rifTgBase, rifRigheSchedaTg.rifMisureRighes, rifRigheSchedaTg.rifMisureRighes.rifTaglie",
        },
        queryOptions: {
          enabled: true
        },
        onSearch: (values: any) => {
            console.log(values);
            return [

                        {
                            field: "cerca",
                            operator: "contains",
                            value: values.description.replaceAll(' ', '%')
                        },
                        // {
                        //     field: "note",
                        //     operator: "contains",
                        //     value: values.description
                        // },
                        // {
                        //     field: "descScheda",
                        //     operator: "contains",
                        //     value: values.description.replaceAll(' ', '%')
                        // },
                        // {
                        //     field: "rifAnno][anno]",
                        //     operator: "contains",
                        //     value: values.description
                        // },
                        // {
                        //     field: "rifStagione][nomeStagione]",
                        //     operator: "contains",
                        //     value: values.description
                        // },
                        // {
                        //     field: "rifStatoSchedaTaglie][descStatoSchedaTaglie]",
                        //     operator: "contains",
                        //     value: values.description
                        // },

                // {
                //     field: 'isUltimaVersione',
                //     operator: 'eq',
                //     value: true
                // }
            ];
        },

        filters: {
            permanent: [
                {
                    field: 'rifDitta][id]',
                    operator: (permission === 'admin') ? 'nnull' : 'eq',
                    value: (permission === 'admin') ? undefined : ditta
                },
                {
                    field: 'rifStatoSchedaTaglie][isDaControllare]',
                    operator: (permission === 'admin') ? 'nnull' : 'eq',
                    value: (permission === 'admin') ? undefined : true
                }
            ]
        },
        pagination: {
            pageSize: 25,
            mode: "server"
        },

        sorters: {
            permanent: [
                {
                    field: 'updatedAt',
                    order: 'desc'
                }
            ]
        }
    });

    // const {tableQueryResult: resultFigli } = useTable<ISchedeTaglie>({
    //     meta: {
    //         populate: "rifAnno, rifStagione, rifStatoSchedaTaglie",
    //         // populate: "rifAnno, rifDitta, foto, figurino, allegati, rifStagione, rifTgBase, rifStatoSchedaTaglie, rifStoriaSchedeTaglie, rifGruppoTaglie, rifGruppoTaglie.rifTg, rifRigheSchedaTg, rifRigheSchedaTg.rifTgBase, rifRigheSchedaTg.rifMisureRighes, rifRigheSchedaTg.rifMisureRighes.rifTaglie",
    //     },
    //     queryOptions: {
    //         enabled: false
    //     },
    //     pagination: {
    //         pageSize: 99999,
    //         mode: "server"
    //     },
    //
    //     sorters: {
    //         permanent: [
    //             {
    //                 field: 'versione',
    //                 order: 'desc'
    //             },
    //             {
    //                 field: 'sottoVersione',
    //                 order: 'desc'
    //             }
    //         ]
    //     }
    // });
    //
    // const [dataSchede, setDataSchede] = useState<any[]>([]);
    // useEffect(() => {
    //     setLoading(true);
    //     // const maxObj = tableQueryResult.data?.data.reduce((accumulator, current) => {
    //     //     return accumulator.nomeScheda === current.nomeScheda ? accumulator : current;
    //     // });
    //     const groupBy = function(xs: any, key: any) {
    //         return xs.reduce(function(rv: any, x: any) {
    //             (rv[x[key]] = rv[x[key]] || []).push(x);
    //             return rv;
    //         }, {});
    //     };
    //     resultFigli.refetch().then((figli: any) => {
    //         if (figli && figli.data && figli.data.data && figli.data.data.length > 0) {
    //             // console.log(figli.data.data);
    //             tableQueryResult.refetch().then((r: any ) => {
    //                 const data = [];
    //                 if (r && r.data && r.data.data && r.data.data.length > 0) {
    //                     // console.log(r);
    //                     const parentSchede = groupBy(r.data.data, 'nomeScheda');
    //                     // console.log(parentSchede);
    //                     for (const s of Object.keys(parentSchede)) {
    //                         // console.log(parentSchede[s]);
    //                         const maxId = Math.max(...parentSchede[s].map((o: any) => o.id));
    //                         const padreIndex = parentSchede[s].findIndex((m: any) => m.id === maxId );
    //                         // console.log(figli.data?.data.filter((f: any) => f.nomeScheda === parentSchede[s][padreIndex].nomeScheda && f.id !== parentSchede[s][padreIndex].id));
    //                         parentSchede[s][padreIndex].children = figli.data?.data.filter((f: any) => f.nomeScheda === parentSchede[s][padreIndex].nomeScheda && f.id !== parentSchede[s][padreIndex].id);
    //                         parentSchede[s][padreIndex].key = parentSchede[s][padreIndex].id;
    //                         data.push(parentSchede[s][padreIndex]);
    //
    //                         // data.map((p: any) => {
    //                         //     p.key = p.id;
    //                         //     p.children = [];
    //                         //     const child: any = resultFigli.data?.data.filter((f: any) => f.nomeScheda === p.nomeScheda);
    //                         //     // console.log(child);
    //                         //     if (child && child.length > 0) {
    //                         //         child.map((x: any) => {
    //                         //             x.key = x.id;
    //                         //             p.children.push(x)
    //                         //         })
    //                         //     }
    //                         //
    //                         // });
    //                         // console.log(data);
    //                     }
    //                     setLoading(false);
    //                     setDataSchede([...data]);
    //                     // console.log(data);
    //                 } else {
    //                     console.log('NO DATA');
    //                     setDataSchede([]);
    //                     setLoading(false);
    //                 }
    //
    //             })
    //         }
    //
    //     })

        // if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data && tableQueryResult.data.data.length > 0) {
        //
        //     tableQueryResult.data?.data.map((p: any) => {
        //         p.key = p.id;
        //         p.children = [];
        //         const child: any = resultFigli.data?.data.filter((f: any) => f.nomeScheda === p.nomeScheda);
        //         // console.log(child);
        //         if (child && child.length > 0) {
        //             child.map((x: any) => {
        //                 x.key = x.id;
        //                 p.children.push(x)
        //             })
        //         }
        //
        //     });
        //         setDataSchede([...tableQueryResult.data?.data]);
        // }
    // }, [tableQueryResult.isFetched, tableQueryResult.data?.data.length])

    const {data: selectListStagioni} = useList({
        resource: 'stagionis',
    });

    const {data: selectListAnni} = useList({
        resource: 'annis',
    });

    const columnStagioni: any = selectListStagioni?.data.map((s: any) => {
        return {
            text: s.nomeStagione,
            value: s.id
        }
    });

    const columnAnni: any = selectListAnni?.data.map((s: any) => {
        // console.log(s);
        return {
            text: s.anno,
            value: s.anno
        }
    });

    const createSchedaTaglie = useCreate<ISchedeTaglie>();
    const onCreate = () => {
        console.log('CREO');
        createSchedaTaglie.mutateAsync({
            resource: 'schede-taglies',
            values: {
                nomeScheda: '',
                versione: 1,
                sottoVersione: 0,
                strVersione: '1.0',
                isUltimaVersione: true,
                rifStatoSchedaTaglie: statoSchedaResult.data?.data[0].id
            }
        }).then( (res: any) => {
            console.log(res);
            edit('schede-taglies', res.data.data.id);
        })

    }

    const {selectProps: statoSchedeSelectProps, queryResult: statoSchedaResult} = useSelect<IStatiSchedeTaglie>({
        pagination: {
            pageSize: 100
        },
        resource: 'stati-schede-taglies',
        meta: {
            populate: '*'
        },
        filters: [
            {
                field: 'isDefault',
                operator: 'eq',
                value: true
            }
        ],
        optionValue: 'id',
        optionLabel: 'descStatoSchedaTaglie',
    });


    // const createRigheSchedaTg = useCreate<IRigheSchedeTaglie>();
    // const creteMisureRighe = useCreate<IMisureRighe>();
    // const updateSchedeTaglie = useUpdate<ISchedeTaglie>();
    // const filterTg = useRef([]);

    // async function creoRighe(e: any) {
    //     // CREO RIGHE SCHEDA TAGLIE CON ID RIF SCHEDA
    //     for (let righeStd of e.rifRigheSchedaTg) {
    //         createRigheSchedaTg.mutateAsync({
    //             resource: 'righe-schede-taglies',
    //             values: {
    //                 codiceRiga: righeStd.codiceRiga,
    //                 letteraRiga: righeStd.letteraRiga,
    //                 numeroRiga: righeStd.numeroRiga,
    //                 descrizioneRiga: righeStd.descrizioneRiga,
    //                 codiceBaseRiga: righeStd.codiceBaseRiga,
    //                 rifGruppoTg: righeStd.rifGruppoTg,
    //                 rifTgBase: righeStd.rifTgBase,
    //                 tolleranzaRiga: righeStd.tolleranzaRiga,
    //                 isTolleranzaPercentualeRiga: righeStd.isTolleranzaPercentualeRiga,
    //                 misuraTgBase: righeStd.misuraTgBase,
    //                 scalaTg: righeStd.scalaTg,
    //                 versione: righeStd.versione,
    //                 isBanda: righeStd.isBanda,
    //                 isTaglioRif: righeStd.isTaglioRif,
    //                 posizioneTgBase: righeStd.posizioneTgBase,
    //                 lBandaTgBase: righeStd.lBandaTgBase,
    //                 isModificata: false,
    //                 isModMisuraTgBase: false,
    //                 isModPosizioneTgBase: false,
    //                 isModBandaTgBase: false,
    //                 rifRigaPadre: 0
    //             }
    //         }) .then( (resR: any) => {
    //             console.log('Inserimento Righe Scheda Taglie: ', resR);
    //             // @ts-ignore
    //             filterTg.current.push(resR.data.data.id);
    //             righeStd.rifMisureRighes.map((r: any) => {
    //                 console.log('XXXX: ', r);
    //                 creteMisureRighe.mutateAsync({
    //                     resource: 'misure-righes',
    //                     values: {
    //                         rifRigheSchedeTg: resR.data.data.id,
    //                         misura: parseFloat(r.misura).toFixed(1),
    //                         rifTaglie: r.rifTaglie.id,
    //                         isCambiata: false,
    //                         min: r.min,
    //                         max: r.max,
    //                         misuraOriginale: r.misura,
    //                     }
    //                 }).then((resR: any) => {
    //                     console.log('Inserimento Righe Scheda Taglie: ', resR);
    //                 })
    //             });
    //         })
    //     };
    // };

    // const printPdf = (e: any) => {
    //     console.log(e);
    //     const input: any = document.getElementById('pdfPrint');
    //     html2canvas(input).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const a4WidthMm = 410;
    //         const pdf: any = new jsPDF({
    //             orientation: "landscape"
    //         });
    //         pdf.addImage(imgData, 'PNG', 0, 0);
    //         pdf.save("download.pdf");
    //     });
    // }

    // const cloneRecord = async (e: any) => {
    //     console.log(e);
    //     await creoRighe(e);
    //     createSchedaTaglie.mutateAsync({
    //         resource: 'schede-taglies',
    //         values: {
    //             nomeScheda: null,
    //             note: e.note,
    //             descScheda: e.descScheda,
    //             versione: 1,
    //             sottoVersione: 0,
    //             strVersione: '1.0',
    //             dataCreazione: new Date().toISOString(),
    //             rifAnno: e.rifAnno.id,
    //             rifDitta: e.rifDitta.id,
    //             rifStagione: e.rifStagione.id,
    //             rifStatoSchedaTaglie: e.rifStatoSchedaTaglie[0].id,
    //             rifGruppoTaglie: e.rifGruppoTaglie.id,
    //             rifTgBase: e.rifTgBase.id
    //         }
    //     }).then((res: any) => {
    //         console.log(res);
    //         // console.log(res);
    //         updateSchedeTaglie.mutateAsync({
    //             resource: 'schede-taglies',
    //             id: Number(res.data.data.id),
    //             values: {
    //                 rifRigheSchedaTg: filterTg.current,
    //             }
    //         }).then( r => {
    //             console.log(r);
    //         })
    //     });
    // }

    // Create styles
    // const styles = StyleSheet.create({
    //     page: {
    //         backgroundColor: "#d11fb6",
    //         color: "white",
    //     },
    //     section: {
    //         margin: 10,
    //         padding: 10,
    //     },
    //     viewer: {
    //         width: window.innerWidth,
    //         height: window.innerHeight,
    //     },
    // });

    return (
        <List title={'Schede'} headerButtons={
            <>
                <Form {...searchFormProps} layout="inline">
                    <Form.Item name="description">
                        <Input placeholder="Search..." allowClear={true} onChange={searchFormProps.form?.submit}/>
                    </Form.Item>
                    {/*<Button onClick={searchFormProps.form?.submit} type={'primary'} style={{backgroundColor: 'yellowgreen'}}>Cerca</Button>*/}
                </Form>
                <CreateButton onClick={onCreate} />
            </>
        }>
            <Table
                id={'pdfPrint'}
                {...tableProps}
                size={"small"}
                loading={loading}
                rowKey="id">
                <Table.Column
                    dataIndex="nomeScheda"
                    title="Nome Scheda"
                    sorter={(a: any, b: any) => {
                        // console.log(a.letteraRiga.length,b.letteraRiga.length);
                        return a.nomeScheda.localeCompare(b.nomeScheda)
                    }}
                />
                <Table.Column
                    dataIndex="riferimentoClone"
                    title="Scheda Clonata"
                    render={(value, record: any) => <TagField color={"orange-inverse"} value={record?.riferimentoClone} style={{
                        cursor: 'pointer'
                    }} onClick={() => edit('schede-taglies', Number(record?.idClone))} />}
                />
                <Table.Column
                    dataIndex="dataCreazione"
                    title="Data"
                    defaultSortOrder={'descend'}
                    sorter={(a: any, b: any) => {
                        // console.log(a.letteraRiga.length,b.letteraRiga.length);
                        return moment(a.dataCreazione).unix() - moment(b.dataCreazione).unix()
                    }}
                    render={(value) => <TagField color={"green"} value={dayjs(value).format('DD/MM/YYYY')} />}
                />
                <Table.Column
                    dataIndex="updatedAt"
                    title="Ultimo Aggiornamento"
                    defaultSortOrder={'descend'}
                    sorter={(a: any, b: any) => {
                        // console.log(a.letteraRiga.length,b.letteraRiga.length);
                        return moment(a.updatedAt).unix() - moment(b.updatedAt).unix()
                    }}
                    render={(value) => <TagField color={"green"} value={dayjs(value).format('DD/MM/YYYY')} />}
                />
                <Table.Column
                    dataIndex="descScheda"
                    title="Descrizione"
                />
                <Table.Column
                    dataIndex="strVersione"
                    title="Versione"
                />
                <Table.Column
                    dataIndex="rifStagione"
                    title="Stagione"
                    filters={columnStagioni}
                    onFilter={(value: any, record: any) => {
                        return record?.rifStagione === value}}
                    render={(value, record) => <TagField color={"cyan"} value={record?.nomeStagione} />}
                />
                <Table.Column
                    dataIndex="anno"
                    title="Anno"
                    filters={columnAnni}
                    onFilter={(value: any, record: any) => {
                        return record?.anno === value}}
                    render={(value, record) => <TagField color={"warning"} value={record?.anno} />}
                />
                <Table.Column
                    dataIndex="rifStatoSchedaTaglie"
                    title="Stato"
                    render={(value, record: any) => <TagField color={"orange"} value={record?.descStatoSchedaTaglie} />}
                />
                <Table.Column<ISchedeTaglie>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <Tooltip placement="top" title={'Edita'}>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                </Tooltip>
                                {/*<Tooltip placement="top" title={'Clona'}>*/}
                                {/*<Button*/}
                                {/*    size={'small'}*/}
                                {/*    icon={<Icons.CopyOutlined />}*/}
                                {/*    onClick={() => cloneRecord(record)}*/}
                                {/*/>*/}
                                {/*</Tooltip>*/}
                                {/*<DeleteButton*/}
                                {/*    size="small"*/}
                                {/*    recordItemId={record.id}*/}
                                {/*    hideText*/}
                                {/*    onSuccess={(value) => {*/}
                                {/*        setTimeout(() => {*/}
                                {/*            tableQueryResult.refetch();*/}
                                {/*        }, 500)*/}

                                {/*    }}*/}
                                {/*/>*/}
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
