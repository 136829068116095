import { List, TagField, useTable, EditButton, DeleteButton, CreateButton } from "@refinedev/antd";
import { Table, Space, Form, Input, Button } from "antd";

import React from 'react';
import {IDitte} from '../../interfaces';

export const DitteList: React.FC = () => {
    const { tableProps, setFilters, searchFormProps } = useTable<IDitte>({
        meta: {
            populate: "*",
        },

        onSearch: (values: any) => {
            console.log(values);
            return [
                {
                    operator: "or",
                    value: [
                        {
                            field: "descDitta",
                            operator: "contains",
                            value: values.description.replace(' ', '%')
                        },
                        {
                            field: "emailDitta",
                            operator: "contains",
                            value: values.description
                        }
                    ]
                },
            ];
        }
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
        [{
            field: 'user][id]',
            operator: 'eq',
            value: d
        }])
    }

    return (
        <List title={'Ditte'} breadcrumb={false} headerButtons={
            <>
                <Form {...searchFormProps} layout="inline">
                    <Form.Item name="description">
                        <Input placeholder="Search..." allowClear={true}/>
                    </Form.Item>
                    <Button onClick={searchFormProps.form?.submit} type={'primary'} style={{backgroundColor: 'yellowgreen'}}>Cerca</Button>
                </Form>
                <CreateButton />
            </>
        }>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="descDitta"
                    title="Ditta"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="emailDitta"
                    title="Email"
                />
                <Table.Column<IDitte>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
