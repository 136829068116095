import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act
[policy_definition]
p = sub, obj, act, eft
[role_definition]
g = _, _
[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))
[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, admin, dashboard, (list)
p, admin, schede-taglies, (list)|(edit)|(create)|(delete)
p, admin, anag, (list)|(edit)|(create)|(delete)
p, admin, dittes, (list)|(edit)|(create)|(delete)
p, admin, users, (list)|(edit)|(create)|(delete)
p, admin, anat, (list)|(edit)|(create)|(delete)
p, admin, taglies, (list)|(edit)|(create)|(delete)
p, admin, gruppo-taglies, (list)|(edit)|(create)|(delete)
p, admin, righe-std-scheda-taglies, (list)|(edit)|(create)|(delete)
p, admin, stati-schede-taglies, (list)|(edit)|(create)|(delete)
p, admin, stagionis, (list)|(edit)|(create)|(delete)
p, admin, annis, (list)|(edit)|(create)|(delete)
p, controller, schede-taglies, (list)|(edit)|(create)
`);
