import { List, TagField, useTable, EditButton, DeleteButton, TextField } from "@refinedev/antd";
import { Table, Space } from "antd";

import {IGruppoTaglie, ITaglie} from 'interfaces';
import React from 'react';

export const GruppoTaglieList: React.FC = () => {
    const { tableProps, setFilters } = useTable<IGruppoTaglie>({
        meta: {
            populate: "rifTg, rifGruppoTg",
        }
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
        [{
            field: 'user][id]',
            operator: 'eq',
            value: d
        }])
    }

    return (
        <List title={'Taglie'} breadcrumb={false}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="descGruppoTg"
                    title="Tipo Taglia"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="rifTg"
                    title="Taglie"
                    render={(value) => {
                        return (
                            <TextField
                                value={
                                    value?.sort((a: any, b: any) => a.ordTg - b.ordTg).map((item: any) =>
                                        <TagField key={item.id} color={"blue"} value={item?.descTg} />
                                    )
                                }
                            />
                        );
                    }}
                />
                <Table.Column<ITaglie>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
