import { useForm, Edit, useSelect } from "@refinedev/antd";
import { Form, Input, Table, Select } from "antd";
import {IGruppoTaglie, ITaglie} from 'interfaces';
import React, {useEffect} from 'react';

interface sedi {
    label: string;
    value: number;
}
export const GruppoTaglieEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm<IGruppoTaglie>({
        meta: {
            populate: "rifTg",
        },
    });

    const { selectProps: taglieSelectProps } = useSelect<ITaglie>({
        pagination: {
          pageSize: 100
        },

        resource: "taglies",
        optionValue: 'id',
        optionLabel: 'descTg',

        sorters: [
            {
                field: 'ordTg',
                order: 'asc'
            }
        ]
    });

    useEffect( () => {
        const sed: any = [];
        queryResult?.data?.data?.rifTg.map( s => {
            sed.push({label: s.descTg, value: s.id});
        })
        formProps.form?.setFields([{name: ["rifTg"], value: sed}])
    }, [formLoading])


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Tipo Taglia"
                    name="descGruppoTg"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Taglie"
                    name={["rifTg"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...taglieSelectProps}
                            mode={'multiple'}

                    />
                </Form.Item>
            </Form>
        </Edit>
    );
};
