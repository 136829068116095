import { List, TagField, useTable, EditButton, DeleteButton } from "@refinedev/antd";
import { Table, Space, Switch } from "antd";

import {IStatiSchedeTaglie} from 'interfaces';
import React from 'react';

export const StatiSchedeTaglieList: React.FC = () => {
    const { tableProps, setFilters } = useTable<IStatiSchedeTaglie>({
        resource: 'stati-schede-taglies',

        meta: {
            populate: "*",
        }
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
        [{
            field: 'user][id]',
            operator: 'eq',
            value: d
        }])
    }

    return (
        <List title={'Stato Schede Taglie'} breadcrumb={false}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="descStatoSchedaTaglie"
                    title="Stato Schede Taglie"
                    render={(value) => value }
                />
                <Table.Column
                    dataIndex="isAperta"
                    title="Aperta"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column
                    dataIndex="isDaControllare"
                    title="Da Controllare"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column
                    dataIndex="isSegnalata"
                    title="Segnalata"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column
                    dataIndex="isControllata"
                    title="Controllata"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column
                    dataIndex="isBloccata"
                    title="Bloccata"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column
                    dataIndex="isPubblicata"
                    title="Pubblicata"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column
                    dataIndex="isDefault"
                    title="Default"
                    render={(value) => <Switch checked={value} />}
                />
                <Table.Column<IStatiSchedeTaglie>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    resource={'stati-schede-taglies'}
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
