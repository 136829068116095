import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Switch, Table } from "antd";

import {ITaglie} from 'interfaces';
import React from 'react';

export const TaglieCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<ITaglie>();
    const handleSubmit = (e: any) => {
        console.log(e);
        onFinish(e).then();
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Taglia"
                    name="descTg"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ordinamento"
                    name={["ordTg"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Default Taglia Base"
                    name={["isUnaTgBase"]}
                    valuePropName={'checked'}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Create>
    );
};
