import {stringify} from 'qs';
import { MeOptions } from "@refinedev/strapi-v4/src/helpers/auth";
import {IUser} from '../interfaces';
import {axiosInstance} from '../authProvider';
import {API_URL, TOKEN_KEY} from '../constants';
import jwt_decode from 'jwt-decode';

export const UtilityHelper = (apiUrl: string) => ({
    permission: async (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.rifGruppoUser?.isAmministrator) ? 'admin' : decoded.rifGruppoUser?.isUtente ? 'user' : decoded.rifGruppoUser?.isControllore ? 'controller' : 'user'
        } else {
            return 'user';
        }

    },
    getDitta: () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.rifDitta) ? decoded.rifDitta.id : null
        }
    },
    getTipo: (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.rifGruppoUser?.isAmministrator) ? 'admin' : decoded.rifGruppoUser?.isUtente ? 'user' : decoded.rifGruppoUser?.isControllore ? 'controller' : 'user'
        } else {
            return 'user';
        }

    },
});
