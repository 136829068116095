import {
    List,
    TextField,
    TagField,
    DateField,
    useTable,
    EditButton,
    DeleteButton,
    CreateButton,
} from "@refinedev/antd";

import { Table, Space, Form, Input, Button } from "antd";

import {IDitte, IUser} from 'interfaces';
import React from 'react';

export const UserList: React.FC = () => {
    const { tableProps, setFilters, searchFormProps } = useTable<IUser>({
        meta: {
            populate: "*",
        },

        onSearch: (values: any) => {
            console.log(values);
            return [
                {
                    operator: "or",
                    value: [
                        {
                            field: "cognome",
                            operator: "contains",
                            value: values.description.replace(' ', '%')
                        },
                        {
                            field: "nome",
                            operator: "contains",
                            value: values.description.replace(' ', '%')
                        },
                        {
                            field: "matricola",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "email",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "username",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "matricola",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "rifDitta][descDitta]",
                            operator: "contains",
                            value: values.description
                        }
                    ]
                },
            ];
        }
    });


    return (
        <List title={'Utenti'} breadcrumb={false} headerButtons={
            <>
                <Form {...searchFormProps} layout="inline">
                    <Form.Item name="description">
                        <Input placeholder="Search..." allowClear={true}/>
                    </Form.Item>
                    <Button onClick={searchFormProps.form?.submit} type={'primary'} style={{backgroundColor: 'yellowgreen'}}>Cerca</Button>
                </Form>
                <CreateButton />
            </>
        }>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="nome"
                    title="Nome"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="cognome"
                    title="Cognome"
                    render={(value) => <TagField color={"red"} value={value} />}
                />
                <Table.Column
                    dataIndex="email"
                    title="Email"
                />
                <Table.Column
                    dataIndex="matricola"
                    title="Matricola"
                />
                <Table.Column
                    dataIndex="rifGruppoUser"
                    title="Gruppo"
                    render={(value) => <TagField color={"blue"} value={value?.descGruppo} />}
                />
                <Table.Column
                    dataIndex="rifDitta"
                    title="Ditta"
                    render={(value) => <TagField color={"lime"} value={value?.descDitta} />}
                />
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
