import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Table } from "antd";

import {ITaglie} from 'interfaces';
import React from 'react';

export const GruppoTaglieCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<ITaglie>();
    const handleSubmit = (e: any) => {
        console.log(e);
        onFinish(e).then();
    }

    const { selectProps: taglieSelectProps } = useSelect<ITaglie>({
        pagination: {
            pageSize: 100
        },

        resource: "taglies",
        optionValue: 'id',
        optionLabel: 'descTg',

        sorters: [
            {
                field: 'ordTg',
                order: 'asc'
            }
        ]
    });


    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Tipo Taglia"
                    name="descGruppoTg"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Taglie"
                    name={["rifTg"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...taglieSelectProps}
                            mode={'multiple'}

                    />
                </Form.Item>
            </Form>
        </Create>
    );
};
