import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Switch, Table } from "antd";

import React from 'react';
import {IAnno} from '../../interfaces';

export const AnnoCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IAnno>();
    const handleSubmit = (e: any) => {
        console.log(e);
        onFinish(e).then();
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Anno"
                    name="anno"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    );
};
