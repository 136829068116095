import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Switch } from "antd";

import { IUser } from "interfaces";

export const UserCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IUser>();
    const handleSubmit = (e: any) => {
        console.log(e);
        e.username = e.email;
        e.role = 1;
        onFinish(e).then();
        // form.setFieldsValue({ name: 'username', value: e.target.value})
    }

    const { selectProps: gruppoSelectProps } = useSelect<IUser>({
        resource: "gruppo-users",
        optionValue: 'id',
        // @ts-ignore
        optionLabel: 'gruppo.descGruppo',

        pagination: {
            mode: "server"
        }
    });

    const validatePassword = (rule: any, value: any, callback: any) => {
        if (value && value !== "Secret") {
            callback("Error!");
        } else {
            callback();
        }
    };

    const { selectProps: ditteSelectProps } = useSelect<IUser>({
        resource: "dittes",
        optionValue: 'id',
        // @ts-ignore
        optionLabel: 'rifDitta.descDitta',

        pagination: {
            mode: "server"
        }
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Nome"
                    name="nome"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Cognome"
                    name="cognome"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={true}
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={false}
                    label="Matricola"
                    name="matricola"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    hasFeedback
                    rules={[{ required: true, message: 'Please add a password' },
                        { min: 8, message: 'Password must have a minimum length of 8' },
                        {
                            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                            message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                        }]}
                >
                    <Input type={"password"}/>
                </Form.Item>
                <Form.Item
                    label="Riferimento Ditta"
                    name={["rifDitta", "id"]}
                    rules={[
                        {
                            required: true,
                            message: 'Seleziona una ditta!'
                        },
                    ]}
                >
                    <Select {...ditteSelectProps} />
                </Form.Item>
                <Form.Item
                    label="Riferimento Gruppo"
                    name={["rifGruppoUser", "id"]}
                    rules={[
                        {
                            required: true,
                            message: 'Seleziona un Gruppo!'
                        },
                    ]}
                >
                    <Select {...gruppoSelectProps} />
                </Form.Item>
                <Form.Item
                    label="Confirmed"
                    name="confirmed"
                    valuePropName="checked"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Create>
    );
};
