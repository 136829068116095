import { List, TagField, useTable, EditButton, DeleteButton, TextField } from "@refinedev/antd";
import { Table, Space } from "antd";

import {IGruppoTaglie, ITaglie} from 'interfaces';
import React from 'react';

export const TaglieList: React.FC = () => {
    const { tableProps, setFilters } = useTable<ITaglie>({
        meta: {
            populate: "*",
        }
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
        [{
            field: 'user][id]',
            operator: 'eq',
            value: d
        }])
    }

    return (
        <List title={'Taglie'} breadcrumb={false}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="descTg"
                    title="Taglia"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="ordTg"
                    title="Ordinamento"
                />
                <Table.Column
                    dataIndex="isUnaTgBase"
                    title="DefaultTgBase"
                    render={(value) => <TagField color={value ? "green" : 'red'} value={value ? 'SI' : 'NO'} />}
                />
                <Table.Column<ITaglie>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
