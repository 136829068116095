import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Switch, Table } from "antd";

import {IStatiSchedeTaglie} from 'interfaces';
import React from 'react';

export const StatiSchedeTaglieCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IStatiSchedeTaglie>({
        resource: 'stati-schede-taglies',
    });
    const handleSubmit = (e: any) => {
        console.log(e);
        onFinish(e).then();
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Stato Scheda Taglia"
                    name="descStatoSchedaTaglie"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Aperta"
                    name="isAperta"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Da Controllare"
                    name="isDaControllare"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Segnalata"
                    name="isSegnalata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Controllata"
                    name="isControllata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Bloccata"
                    name="isBloccata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Default"
                    name="isDefault"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Pubblicata"
                    name="isPubblicata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Create>
    );
};
