import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Switch, Table } from "antd";

import React from 'react';
import {IDitte} from '../../interfaces';

export const DitteCreate = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IDitte>();
    const handleSubmit = (e: any) => {
        console.log(e);
        onFinish(e).then();
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Form.Item
                    label="Descrizione Ditta"
                    name="descDitta"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="emailDitta"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Telefono"
                    name="telDitta"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="is Gebel"
                    name="isGebel"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Create>
    );
};
