import { Create, SaveButton, TagField, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Col, DatePicker, Form, Input, Row, Select, Switch, Upload } from "antd";
import { Table } from 'antd';
import {
    IGruppoTaglie,
    IRigheStandardSchedeTaglie,
    ISchedeTaglie, IStagioni,
    IStatiSchedeTaglie
} from 'interfaces';
import React, {useState} from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {ColumnsType} from 'antd/es/table';
export const SchedeTaglieCreate = () => {
    const {formProps, saveButtonProps, onFinish} = useForm<ISchedeTaglie>();
    const handleSubmit = (e: any) => {
        console.log(e);
    }

    const [filterTaglie, setFilterTaglie] = useState();

    const {selectProps: codiciSelectProps, queryResult: resultCodici} = useSelect<IRigheStandardSchedeTaglie>({
        pagination: {
            pageSize: 100
        },
        queryOptions: {
            enabled: false
        },
        resource: 'righe-std-scheda-taglies',
        meta: {
            populate: 'rifGruppoTg, rifTgBase, rifMisureRigheStds, rifMisureRigheStds.rifTaglie'
        },
        filters: [
            {
                field: 'rifGruppoTg][id]',
                operator: 'eq',
                value: filterTaglie
            }
        ],
        optionValue: 'id',
        optionLabel: 'codiceRigaStd',
    });

    const {selectProps: gruppoTaglieSelectProps, queryResult} = useSelect<IGruppoTaglie>({
        pagination: {
            pageSize: 100
        },
        resource: 'gruppo-taglies',
        meta: {
            populate: '*'
        },
        optionValue: 'id',
        optionLabel: 'descGruppoTg',
    });

    const {selectProps: statoSchedeSelectProps, queryResult: statoSchedaResult} = useSelect<IStatiSchedeTaglie>({
        pagination: {
            pageSize: 100
        },
        resource: 'stati-schede-taglies',
        meta: {
            populate: '*'
        },
        filters: [
            {
                field: 'isDefault',
                operator: 'eq',
                value: true
            }
        ],
        optionValue: 'id',
        optionLabel: 'descStatoSchedaTaglie',
    });

    const {selectProps: stagioniSchedeSelectProps} = useSelect<IStagioni>({
        pagination: {
            pageSize: 100
        },
        resource: 'stagionis',
        meta: {
            populate: '*'
        },
        optionValue: 'id',
        optionLabel: 'nomeStagione',
    });


    const [taglie, setTaglie] = useState<any>();
    const [showMisure, setShowMisure] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [righeM, setRigheM] = useState<any>([]);
    const getTaglie = (data: any) => {
        setFilterTaglie(data);
        setTimeout(() => {
            // console.log(filterTaglie)
            resultCodici.refetch();
        }, 1000);
        setRigheM([]);
        const tg = queryResult.data?.data?.find((item => item.id === data));
        const foundTg = tg?.rifTg?.map(item => ({
            label: item.descTg,
            value: item.id,
        }));
        setTaglie(foundTg);

    }

    interface DataType {
        id: string;
        name: string;
        age: number;
        address: string;
    }
    const addToCart = (d: any) => {
        const codice: any = resultCodici.data?.data.find((x: any) => x.id === d) || {};
        codice.key = codice.id;
        // IMPOSTO ORDINAMENTO
        const righe: any = resultCodici?.data?.data[0].rifMisureRigheStds.sort((s1, s2) => {
            return s1.rifTaglie.ordTg - s2.rifTaglie.ordTg;
        });
        righe.map(
            (obj: any) =>   { codice[obj.rifTaglie.descTg] = obj.misura
        });
        const col: ColumnsType<DataType> = [
            {
                key: 'sort',
                width: 10,
                align: 'center'
            },
            {
                title: 'Codice',
                dataIndex: 'codiceRigaStd',
                key: 'codiceRigaStd',
                width: 30,
                align: 'center'
            },
            {
                title: 'Descrizione',
                dataIndex: 'descRigaStd',
                key: 'descRigaStd',
                width: 130,
                align: 'center'
            },
            {
                title: 'isBnd',
                dataIndex: 'isBanda',
                key: 'isBanda',
                width: 20,
                align: 'center',
                render: (value) => {
                    return <TagField value={value ? 'SI': 'NO'} color={value ? 'green': 'red'}/>
                }
            },
            {
                title: 'isTgRif',
                dataIndex: 'isTaglioRif',
                key: 'isTaglioRif',
                width: 20,
                align: 'center',
                render: (value) => {
                    return <TagField value={value ? 'SI': 'NO'} color={value ? 'green': 'red'}/>
                }
            },
            {
                title: 'TgBase',
                dataIndex: 'rifTgBase',
                key: 'rifTgBase',
                width: 20,
                align: 'center',
                render: (value: any, record: any) =>
                    value?.descTg
            },
            {
                title: 'posTb',
                dataIndex: 'posizioneTgBase',
                key: 'posizioneTgBase',
                width: 20,
                align: 'center'
            },
            {
                title: 'lgBndTb',
                dataIndex: 'lBandaTgBase',
                key: 'lBandaTgBase',
                width: 20,
                align: 'center'
            },
            {
                title: 'Ms Base',
                dataIndex: 'misuraTgBase',
                key: 'misuraTgBase',
                width: 50,
                align: 'center'
            },
            {
                title: 'Scala',
                dataIndex: 'scalaTg',
                key: 'scalaTg',
                width: 20,
                align: 'center'
            }
        ]
        resultCodici?.data?.data[0].rifMisureRigheStds.map((item: any) => {
            // console.log(item);
            col.push({
                title: item.rifTaglie.descTg,
                dataIndex: item.rifTaglie.descTg,
                key: item.rifTaglie.descTg,
                width: 100,
                align: 'center',
                render: (value: any, record: any) => (
                    <Input defaultValue={value} />
                ),
            })
        })

        setColumns(col);
        // console.log(codice);
        setRigheM((current: any) => [...current, codice]);
    }

    interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
        'data-row-key': number;
    }

    const RowS = ({ children, ...props }: RowProps) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });

        const style: React.CSSProperties = {
            ...props.style,
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
                /translate3d\(([^,]+),/,
                'translate3d(0,',
            ),
            transition,
            ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
        };

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child: any) => {
                    if ((child as React.ReactElement).key === 'sort') {
                        return React.cloneElement(child as React.ReactElement, {
                            children: (
                                <Icons.MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{ touchAction: 'none', cursor: 'move' }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setRigheM((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.id === active.id);
                const overIndex = prev.findIndex((i: any) => i.id === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    return (
        <Create saveButtonProps={saveButtonProps} >
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Nome Scheda"
                            name="nomeScheda"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Ver."
                            name="versione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input readOnly={true} defaultValue={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data"
                            name="dataCreazione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Stato"
                            name="rifStatoSchedaTaglie"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select disabled={true} {...statoSchedeSelectProps} defaultValue={{value: statoSchedaResult.data?.data[0].id.toString() || '', label: statoSchedaResult.data?.data[0].descStatoSchedaTaglie || ''}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Stagione"
                            name="rifStagione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...stagioniSchedeSelectProps}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Gruppo Taglie"
                            name={["rifGruppoTg", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...gruppoTaglieSelectProps} onSelect={(e) => getTaglie(e)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Descrizione Scheda"
                            name="descScheda"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Codice"
                            name="rifRigheSchedaTg"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select {...codiciSelectProps} onSelect={(d) => {addToCart(d)}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Immagine"
                            name="figurino"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Upload>Seleziona Immagine...</Upload>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Documenti"
                            name="allegati"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Upload>Seleziona File...</Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <hr/>
                    <DndContext onDragEnd={onDragEnd}>
                        <SortableContext
                            // rowKey array
                            items={righeM.map((i: any) => i.key)}
                            strategy={verticalListSortingStrategy}
                        >
                                <Table
                                    size={'small'}
                                    sticky={false}
                                    components={{
                                        body: {
                                            row: RowS,
                                        },
                                    }}
                                    rowKey="key"
                                    pagination={false}
                                    dataSource={righeM}
                                    columns={columns}>
                                </Table>
                        </SortableContext>
                    </DndContext>

            </Form>
        </Create>
    );
};
