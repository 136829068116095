import React, {useState} from 'react';

import {Authenticated, Refine} from '@refinedev/core';
import {ErrorComponent, ThemedLayout, RefineThemes} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import '@refinedev/antd/dist/reset.css';
// import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import {
  Footer, Login, CustomSider,
} from 'components/layout';
import {authProvider, axiosInstance} from './authProvider';
import {DataProvider} from './dataProvider';
import { API_URL } from "./constants";
import {notificationProvider} from './notificationProvider';
import {ToastContainer} from 'react-toastify';
import {UserCreate, UserEdit, UserList} from './pages';
import {newEnforcer} from 'casbin';
import {adapter, model} from './accessControl';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import routerProvider, {CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier} from '@refinedev/react-router-v6';
import {ConfigProvider, theme} from 'antd';
import {UtilityHelper} from './helper/utility';
import {SchedeTaglieCreate, SchedeTaglieEdit, SchedeTaglieList} from './pages/schede-taglies';
import {DitteCreate, DitteEdit, DitteList} from './pages/dittes';
import {TaglieCreate, TaglieEdit, TaglieList} from './pages/taglies';
import {GruppoTaglieCreate, GruppoTaglieEdit, GruppoTaglieList} from './pages/gruppo-taglies';
import {
  RigheStandardSchedaTaglieCreate,
  RigheStandardSchedaTaglieEdit,
  RigheStandardSchedaTaglieList
} from './pages/righe-standard-sheda-taglies';
import {StatiSchedeTaglieCreate, StatiSchedeTaglieEdit, StatiSchedeTaglieList} from './pages/stati-schede-taglies';
import {StagioniCreate, StagioniEdit, StagioniList} from './pages/stagionis';
import {AnnoCreate, AnnoEdit, AnnoList} from './pages/annis';
import {ForgotPasswordPage, UpdatePasswordPage} from './components/pages/auth/components';
import {useTranslation} from 'react-i18next';

function App() {

  const utilityHelper = UtilityHelper(API_URL + '/api');
  const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>('dark');
  const {t, i18n} = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
      <BrowserRouter>
        <ToastContainer />
        <ConfigProvider theme={{
          ...RefineThemes.Orange,
          algorithm:
              currentTheme === 'dark'
                  ? theme.defaultAlgorithm
                  : theme.darkAlgorithm,
          components: {
            Button: {
              borderRadius: 10,
            },
            Typography: {
              colorTextHeading: '#000000',
            },
            Table: {
              headerColor: '#000000'
            },
            Form: {
              labelColor: '#2f2e2e'
            }
          },
          token: {
            colorBgElevated: currentTheme === 'light' ? 'white' : 'white',
            colorTextHeading: currentTheme === 'light' ? 'black' : 'yellow'
          },
        }}>
          <Refine
              authProvider={authProvider}
              dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
              notificationProvider={notificationProvider}
              options={{
                breadcrumb: false,
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                disableTelemetry: true
              }}
              accessControlProvider={{
                can: async ({action, params, resource}) => {
                  const role = await utilityHelper.permission();
                  const enforcer = await newEnforcer(model, adapter);
                  const can = await enforcer.enforce(
                      role,
                      resource,
                      action,
                  );
                  return Promise.resolve({can});
                },
                options: {
                  buttons: {
                    enableAccessControl: true,
                    hideIfUnauthorized: false,
                  },
                },
              }}
              routerProvider={routerProvider}
              i18nProvider={i18nProvider}
              resources={[
                {
                  name: 'schede-taglies',
                  meta: {label: 'Schede', icon: <Icons.OrderedListOutlined />},
                  list: '/schede-taglies',
                  edit: '/schede-taglies/edit/:id',
                  create: '/schede-taglies/create'
                },
                {
                  name: 'anag',
                  meta: {
                    label: 'Gestionale',
                    icon: <Icons.FolderOutlined />
                  },
                },
                {
                  name: 'dittes',
                  identifier: 'dittes',
                  meta: {
                    label: 'Ditte',
                    icon: <Icons.AppstoreOutlined />,
                    parent: 'anag',
                  },
                  list: '/anag/dittes',
                  edit: '/anag/dittes/edit/:id',
                  create: '/anag/dittes/create'
                },
                {
                  name: 'users',
                  identifier: 'users',
                  meta: {
                    label: 'Utenti',
                    icon: <Icons.UserOutlined />,
                    parent: 'anag',
                  },
                  list: '/anag/users',
                  edit: '/anag/users/edit/:id',
                  create: '/anag/users/create'
                },
                {
                  name: 'anat',
                  meta: {
                    label: 'Tecnico',
                    icon: <Icons.ContainerOutlined />
                  },
                },
                {
                  name: 'taglies',
                  identifier: 'taglies',
                  meta: {
                    label: 'Taglie',
                    icon: <Icons.OrderedListOutlined />,
                    parent: 'anat',
                  },
                  list: '/anat/taglies',
                  edit: '/anat/taglies/edit/:id',
                  create: '/anat/taglies/create'
                },
                {
                  name: 'gruppo-taglies',
                  identifier: 'gruppo-taglies',
                  meta: {
                    label: 'Gruppo Taglie',
                    icon: <Icons.OrderedListOutlined />,
                    parent: 'anat',
                  },
                  list: '/anat/gruppo-taglies',
                  edit: '/anat/gruppo-taglies/edit/:id',
                  create: '/anat/gruppo-taglies/create'
                },
                {
                  name: 'righe-std-scheda-taglies',
                  identifier: 'righe-std-scheda-taglies',
                  meta: {
                    label: 'Riga Standard',
                    icon: <Icons.OrderedListOutlined />,
                    parent: 'anat',
                  },
                  list: '/anat/righe-std-scheda-taglies',
                  edit: '/anat/righe-std-scheda-taglies/edit/:id',
                  create: '/anat/righe-std-scheda-taglies/create'
                },
                {
                  name: 'stati-schede-taglies',
                  identifier: 'stati-schede-taglies',
                  meta: {
                    label: 'Stati Schede Taglie',
                    icon: <Icons.OrderedListOutlined />,
                    parent: 'anat',
                  },
                  list: '/anat/stati-schede-taglies',
                  edit: '/anat/stati-schede-taglies/edit/:id',
                  create: '/anat/stati-schede-taglies/create'
                },
                {
                  name: 'stagionis',
                  identifier: 'stagionis',
                  meta: {
                    label: 'Stagioni',
                    icon: <Icons.OrderedListOutlined />,
                    parent: 'anat',
                  },
                  list: '/anat/stagionis',
                  edit: '/anat/stagionis/edit/:id',
                  create: '/anat/stagionis/create'
                },
                {
                  name: 'annis',
                  identifier: 'annis',
                  meta: {
                    label: 'Anni',
                    icon: <Icons.OrderedListOutlined />,
                    parent: 'anat',
                  },
                  list: '/anat/annis',
                  edit: '/anat/annis/edit/:id',
                  create: '/anat/annis/create'
                }
              ]}
          >
            <Routes>
              <Route
                  element={
                    <Authenticated
                        fallback={<CatchAllNavigate to="/login"/>}
                    >
                      <ThemedLayout
                          Sider={() => {
                            return (<CustomSider />)
                          }}
                          Footer={() => {
                            return (<Footer />)
                          }}
                      >
                        <Outlet/>
                      </ThemedLayout>
                    </Authenticated>
                  }
              >
                <Route index element={<NavigateToResource resource="schede-taglies"/>} />
                <Route path="/schede-taglies">
                  <Route index element={<SchedeTaglieList />}/>
                  <Route path="create" element={<SchedeTaglieCreate/>}/>
                  <Route path="edit/:id" element={<SchedeTaglieEdit/>}/>
                </Route>
                <Route path="anag">
                  <Route path="dittes">
                    <Route index element={<DitteList />}/>
                    <Route path="create" element={<DitteCreate/>}/>
                    <Route path="edit/:id" element={<DitteEdit/>}/>
                  </Route>
                  <Route path="users">
                    <Route index element={<UserList/>}/>
                    <Route path="create" element={<UserCreate />}/>
                    <Route path="edit/:id" element={<UserEdit />}/>
                  </Route>
                </Route>
                <Route path="anat">
                  <Route path="taglies">
                    <Route index element={<TaglieList />}/>
                    <Route path="create" element={<TaglieCreate />}/>
                    <Route path="edit/:id" element={<TaglieEdit />}/>
                  </Route>
                  <Route path="gruppo-taglies">
                    <Route index element={<GruppoTaglieList/>}/>
                    <Route path="create" element={<GruppoTaglieCreate />}/>
                    <Route path="edit/:id" element={<GruppoTaglieEdit />}/>
                  </Route>
                  <Route path="righe-std-scheda-taglies">
                    <Route index element={<RigheStandardSchedaTaglieList />}/>
                    <Route path="create" element={<RigheStandardSchedaTaglieCreate />}/>
                    <Route path="edit/:id" element={<RigheStandardSchedaTaglieEdit />}/>
                  </Route>
                  <Route path="stati-schede-taglies">
                    <Route index element={<StatiSchedeTaglieList />}/>
                    <Route path="create" element={<StatiSchedeTaglieCreate />}/>
                    <Route path="edit/:id" element={<StatiSchedeTaglieEdit />}/>
                  </Route>
                  <Route path="stagionis">
                    <Route index element={<StagioniList />}/>
                    <Route path="create" element={<StagioniCreate />}/>
                    <Route path="edit/:id" element={<StagioniEdit />}/>
                  </Route>
                  <Route path="annis">
                    <Route index element={<AnnoList />}/>
                    <Route path="create" element={<AnnoCreate />}/>
                    <Route path="edit/:id" element={<AnnoEdit />}/>
                  </Route>
                </Route>
              </Route>
              <Route
                  element={
                    <Authenticated fallback={<Outlet/>}>
                      <NavigateToResource resource="dashboard"/>
                    </Authenticated>
                  }
              >
                <Route path="/login" element={<Login />}/>
                <Route path="/forgot-password"
                       element={<ForgotPasswordPage title={false} />}/>
                <Route path="/update-password"
                       element={<UpdatePasswordPage title={false} />}/>
              </Route>
              <Route
                  element={
                    <Authenticated>
                      <ThemedLayout>
                        <Outlet />
                      </ThemedLayout>
                    </Authenticated>
                  }
              >
                <Route path="*" element={<ErrorComponent/>}/>
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
          </Refine>
        </ConfigProvider>
      </BrowserRouter>
  );
}

export default App;
