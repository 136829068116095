import { useForm, Edit, useSelect, TagField, getValueFromEvent } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Table,
    Select,
    Row,
    Col,
    DatePicker,
    Upload,
    UploadProps,
    message,
    Image,
    Button,
    Space,
    Popconfirm,
    Tooltip, Tabs, TabsProps, Modal,
} from 'antd';

import {
    IAnno, IDitte,
    IGruppoTaglie, IMisureRighe,
    IRigheSchedeTaglie,
    IRigheStandardSchedeTaglie,
    ISchedeTaglie,
    IStagioni,
    IStatiSchedeTaglie
} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import {ColumnsType} from 'antd/es/table';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {DndContext, DragEndEvent} from '@dnd-kit/core';
import {useCreate, useDelete, useList, useNavigation, useUpdate} from '@refinedev/core';
import dayjs from 'dayjs';
import {API_URL} from '../../constants';
import {getValueProps} from '@refinedev/strapi-v4';
import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf';
import {TableRowSelection} from 'antd/es/table/interface';
const { Dragger } = Upload;

export const SchedeTaglieEdit: React.FC = () => {
    const [taglie, setTaglie] = useState<any>();
    const [showMisure, setShowMisure] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [righeM, setRigheM] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [loadingClone, setLoadingClone] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingPubblic, setLoadingPubblic] = useState(false);
    const [loadingPaste, setLoadingPaste] = useState(false);
    const { formProps, saveButtonProps, queryResult: resultRighe, formLoading, id} = useForm<ISchedeTaglie>({
        meta: {
            populate: 'rifAnno, rifDitta, foto, figurino, allegati, rifStagione, rifTgBase, rifStatoSchedaTaglie, rifStoriaSchedeTaglie, rifGruppoTaglie, rifGruppoTaglie.rifTg, rifRigheSchedaTg, rifRigheSchedaTg.rifTgBase, rifRigheSchedaTg.rifMisureRighes, rifRigheSchedaTg.rifMisureRighes.rifTaglie, rifRigheSchedaTg.rifGruppoTg'
        },
        warnWhenUnsavedChanges: false
    });

    const {selectProps: gruppoTaglieSelectProps, queryResult} = useSelect<IGruppoTaglie>({
        pagination: {
            pageSize: 100
        },
        resource: 'gruppo-taglies',
        meta: {
            populate: '*'
        },
        optionValue: 'id',
        optionLabel: 'descGruppoTg',
        defaultValue: resultRighe?.data?.data?.rifGruppoTaglie?.id
    });

    const {selectProps: annoSelectProps} = useSelect<IAnno>({
        pagination: {
            pageSize: 100
        },
        resource: 'annis',
        meta: {
            populate: '*'
        },
        optionValue: 'id',
        optionLabel: 'anno',
        defaultValue: resultRighe?.data?.data?.anno?.id
    });

    const {selectProps: ditteSelectProps} = useSelect<IDitte>({
        pagination: {
            pageSize: 100
        },
        resource: 'dittes',
        meta: {
            populate: '*'
        },
        filters: [
            {
                field: 'isGebel',
                operator: 'eq',
                value: false
            }
        ],
        optionValue: 'id',
        optionLabel: 'descDitta',
        defaultValue: resultRighe?.data?.data?.rifDitta?.id
    });

    const col: ColumnsType<DataType> = [
        {
            key: 'sort',
            width: 30,
            align: 'center'
        },
        {
            title: '',
            dataIndex: 'numeroRiga',
            key: 'numeroRiga',
            align: 'center',
            width: 40,
            render: (value: any, record: any) => (
                value
            ),
        },
        {
            title: 'Ref',
            dataIndex: 'rifRigaPadre',
            key: 'rifRigaPadre',
            align: 'center',
            width: 40,
            render: (value: any, record: any) => (
                <Input style={{fontSize: 10, border: 'none'}} size={'small'} defaultValue={value} width={70} />
            ),
        },
        {
            title: '',
            dataIndex: 'letteraRiga',
            key: 'letteraRiga',
            align: 'center',
            width: 40,
            // sorter: (a: any, b: any) => {
            //     // console.log(a.letteraRiga.length,b.letteraRiga.length);
            //     return a.letteraRiga.localeCompare(b.letteraRiga)
            // },
            render: (value: any, record: any) => (
                value
            ),
        },
        {
            title: 'Descrizione - Description',
            dataIndex: 'descrizioneRiga',
            key: 'descrizioneRiga',
            width: '80%',
            align: 'left',
            render: (value: any, record: any) =>
                <span style={{ fontSize: 10}}>
                    <Tooltip
                        showArrow={false}
                        color={'rgb(169,94,0)'} placement="top" title={record.codiceRiga}>
                        {value}
                    </Tooltip>
                    </span>
        },
        {
            title: 'TOL',
            dataIndex: 'tolleranzaRiga',
            key: 'tolleranzaRiga',
            width: 40,
            align: 'center',
            render: (value: any, record: any) =>
                <span style={{ fontSize: 10}}>{value}</span>
        },
        {
            title: <Tooltip
                showArrow={false}
                color={'rgb(103,103,103)'} placement="top" title={'Banda'}>
                BD
            </Tooltip>,
            dataIndex: 'isBanda',
            key: 'isBanda',
            width: 40,
            align: 'center',
            render: (value) => {
                return <TagField style={{fontSize: 6, width: 25}} value={value ? 'SI': 'NO'} color={value ? 'green': 'red'}/>
            }
        },
        {
            title: <Tooltip
                showArrow={false}
                color={'rgb(103,103,103)'} placement="top" title={'Taglio Riferimento'}>
                TR
            </Tooltip>,
            dataIndex: 'isTaglioRif',
            key: 'isTaglioRif',
            width: 40,
            align: 'center',
            render: (value) => {
                return <TagField style={{fontSize: 6, width: 25}} value={value ? 'SI': 'NO'} color={value ? 'green': 'red'}/>
            }
        },
        {
            title: <Tooltip
                showArrow={false}
                color={'rgb(103,103,103)'} placement="top" title={'Posizione Taglia Base'}>
                PTB
            </Tooltip>,
            dataIndex: 'posizioneTgBase',
            key: 'posizioneTgBase',
            width: 40,
            align: 'center',
            render: (value: any, record: any) =>
                <span style={{ fontSize: 10}}>{value}</span>
        },
        {
            title: <Tooltip
                showArrow={false}
                color={'rgb(103,103,103)'} placement="top" title={'Larghezza Banda Taglia Base'}>
                LBTB
            </Tooltip>,
            dataIndex: 'lBandaTgBase',
            key: 'lBandaTgBase',
            width: 45,
            align: 'center',
            render: (value: any, record: any) =>
                <span style={{ fontSize: 10}}>{value}</span>
        },
        // {
        //     title: 'MB',
        //     dataIndex: 'misuraTgBase',
        //     key: 'misuraTgBase',
        //     width: 50,
        //     align: 'center',
        //     render: (value: any, record: any) =>
        //         <span style={{ fontSize: 10}}>{value}</span>
        // },
        {
            title: 'Scala',
            dataIndex: 'scalaTg',
            key: 'scalaTg',
            width: 50,
            align: 'center',
            render: (value: any, record: any) =>
                <span style={{ fontSize: 10}}>{value}</span>
        }
    ]


    useEffect(() => {
        getTaglie(resultRighe?.data?.data?.rifGruppoTaglie?.id, false);
        const tgBase = formProps.form?.getFieldValue('rifTgBase');
        if (tgBase && tgBase.id) {
            setFilterTgBaseTaglie(tgBase.id);
        }

    }, [resultRighe?.data?.data?.rifGruppoTaglie?.id])

    const filterTg: any = useRef([]);
    const righeN: any = useRef([]);

    // useEffect(() => {
    //     console.log('UPDATE FILTER TAGLIE: ', filterTaglie);
    //     setFilterTaglie(filterTaglie);
    //     console.log('UPDATE FILTER TAGLIE: ', filterTaglie);
    // }, [filterTaglie]);
    const [reload, setReload] = useState(false);
    const aggiornaRighe = () => {
        console.log('UPDATE!');
        resultRighe?.refetch().then((righe) => {
            // console.log(resultRighe?.data?.data);
            if (righe?.data && righe?.data?.data && righe?.data?.data.figurino) {
                const imgA = [];
                imgA.push(righe?.data?.data.figurino);
                setImg(imgA);
            }

            if (righe?.data && righe?.data?.data && righe?.data?.data.foto) {
                const imgA = [];
                imgA.push(righe?.data?.data.foto);
                setFot(imgA);
            }

            if (righe?.data && righe?.data?.data && righe?.data?.data.allegati && righe?.data?.data.allegati.length > 0) {
                let docA;
                docA = righe?.data?.data.allegati;
                setDoc(docA);
            }

            if (id && righe?.data && righe?.data?.data?.rifRigheSchedaTg.length > 0) {
                setLoading(true);
                // console.log('CARICO DATI...');
                // setFilterTaglie([]);
                filterTg.current = [];
                let misureStd: any = [];
                setRigheM([]);
                setTimeout(() => {
                    const foundTg = righe?.data?.data?.rifGruppoTaglie?.rifTg?.map(item => ({
                        label: item.descTg,
                        value: item.id,
                    }));
                    // console.log(formProps.form?.getFieldValue('rifTgBase'));
                    righe?.data?.data?.rifRigheSchedaTg?.[0]?.rifMisureRighes?.sort((s1: any, s2: any) => {
                        return s1.rifTaglie.ordTg - s2.rifTaglie.ordTg;
                    }).map( (item: any, index: number) => (col.push({
                        title: item.rifTaglie.descTg,
                        dataIndex: item.rifTaglie.descTg,
                        key: item.rifTaglie.descTg,
                        align: 'center',
                        className: (item.rifTaglie.descTg === formProps.form?.getFieldValue('rifTgBase')['descTg'] ? 'tgBase' : ''),
                        width: 60,
                        render: (value: any, record: any, index: number) => {
                            const i = record.rifMisureRighes.find((x: any) => x.rifTaglie.descTg === item.rifTaglie.descTg);
                            const itemId = i?.id;
                            const min = i?.min;
                            const max = i?.max;
                            const ori = i?.misuraOriginale;
                            const isCmb = i?.isCambiata;
                            // console.log(itemId, isCmb);
                            // console.log('INDICE CELLA: ', index, ',', indexCell, ', ', record.rifMisureRighes[indexCell].misuraOriginale);
                            return (
                                <Tooltip
                                    showArrow={false}
                                    color={'rgba(255,9,0,0.54)'} placement="top" title={<div>Min: {min}<br />Max: {max}<br />Ori: {ori}</div>}>
                                    <Input
                                        readOnly={formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isPubblicata || formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isBloccata}
                                        style={{fontSize: 10, border: 'none', color: isCmb ? 'black' : '', background: isCmb ? '#0baede': ''}}
                                        size={'small'}
                                        defaultValue={Number(value).toFixed(1)} width={70}
                                        onPressEnter={(e: any) => onChangeTg(record, itemId, record.id, record.scalaTg, record.rifMisureRighes, e.target.value, value)}/>
                                </Tooltip>
                            )
                        },
                    })))
                    col.push({
                        title: <>
                            <Tooltip
                                showArrow={false}
                                color={'rgb(255,9,0)'} placement="top" title={'Elimina Selezionati'}>
                                <Button size={'small'} icon={<Icons.DeleteRowOutlined />} onClick={eliminaSelezionati} />
                            </Tooltip>
                        </>,
                        dataIndex: 'actions',
                        key: 'actions',
                        width: 40,
                        align: 'center',
                        render: (v: any, record: any) => {
                            return <Popconfirm
                                title="Sei sicuro di voler eliminare la riga?"
                                onCancel={() => message.info('Annullato')}
                                onConfirm={() => deleteRow(Number(record.id))}
                            >
                                <Button size={'small'} style={{ color: 'red'}} icon={<Icons.DeleteOutlined />}  />
                            </Popconfirm>
                        }
                    })
                    setTaglie(foundTg);
                    const tgStdBase = formProps.form?.getFieldValue('rifGruppoTaglie');
                    const tgBase = formProps.form?.getFieldValue('rifTgBase');
                    // console.log(tgBase)
                    setFilterStdTaglie(tgStdBase.id);
                    setFilterTgBaseTaglie(tgBase.id);
                    resultCodici.refetch();
                    righe.data.data.rifRigheSchedaTg.sort((s1: any, s2: any) => {
                        return s1.numeroRiga - s2.numeroRiga;
                    }).map((r: any) => {
                        r.key = r.id;
                        // console.log('IMPOSTO FILTER TAGLIE: ', r.id);
                        // setFilterTaglie((current: any) => [...current, r.id]);
                        // @ts-ignore
                        filterTg.current.push(r.id);
                        // console.log("FILTER TAGLIE: ,", filterTg)
                        const righeE: any = r.rifMisureRighes.sort((s1: any, s2: any) => {
                            return s1.rifTaglie.ordTg - s2.rifTaglie.ordTg;
                        });
                        righeE.map(
                            (obj: any) => { r[obj.rifTaglie.descTg] = obj.misura
                            });
                        // console.log("RIGHE: ,", r)
                        misureStd.push(r);
                    });
                    // console.log(misureStd);
                    setColumns(col);
                    setRigheM(misureStd);
                    righeN.current = misureStd;
                    setLoading(false);
                }, 500)
                // console.log(misureStd)
            }
        })
    }
    useEffect(() => {
        aggiornaRighe();
    }, [resultRighe?.data?.data.rifRigheSchedaTg.length])

    // console.log(resultRighe?.data?.data?.rifRigheSchedaTg.length);
    const {selectProps: statoSchedeSelectProps, queryResult: statoSchedaResult} = useSelect<IStatiSchedeTaglie>({
        resource: 'stati-schede-taglies',
        optionValue: 'id',
        optionLabel: 'descStatoSchedaTaglie',
        defaultValue: resultRighe?.data?.data?.rifStatoSchedaTaglie?.[0]?.id,

        pagination: {
            mode: "server"
        }
    });

    const {selectProps: stagioniSchedeSelectProps} = useSelect<IStagioni>({
        pagination: {
            pageSize: 100
        },
        resource: 'stagionis',
        meta: {
            populate: '*'
        },
        optionValue: 'id',
        optionLabel: 'nomeStagione',
        defaultValue: resultRighe?.data?.data?.rifStagione?.id
    });

    const getTaglie = (data: any, isAuto: boolean) => {
        // console.log(data);
        setFilterStdTaglie(data);
        setTimeout(() => {
            // console.log(filterStdTaglie)
            resultCodici.refetch();
        }, 1000);
        setRigheM([]);
        righeN.current = [];
        const tg = queryResult.data?.data?.find((item => item.id === data));
        const foundTg = tg?.rifTg?.map(item => ({
            label: item.descTg,
            value: item.id,
        }));
        setTaglie(foundTg);

        if (isAuto) {
            setTimeout(() => {
                const isTagliaBase: any = tg?.rifTg.find((t: any) => t.isUnaTgBase);
                // console.log(isTagliaBase);
                if (!resultRighe?.data?.data?.rifTgBase?.id) {
                    formProps.form?.setFieldsValue({'rifTgBase': isTagliaBase})
                    // console.log(isTagliaBase);
                    setFilterTgBaseTaglie(isTagliaBase.id);
                }
            }, 500)
        }

    }

    const [filterStdTaglie, setFilterStdTaglie] = useState();
    const [filterTgBaseTaglie, setFilterTgBaseTaglie] = useState();

    const {selectProps: codiciSelectProps, queryResult: resultCodici} = useSelect<IRigheStandardSchedeTaglie>({
        pagination: {
            pageSize: 100
        },
        queryOptions: {
            enabled: true
        },
        resource: 'righe-std-scheda-taglies',
        meta: {
            populate: 'rifGruppoTg, rifTgBase, rifMisureRigheStds, rifMisureRigheStds.rifTaglie'
        },
        filters: [
            {
                field: 'rifGruppoTg][id]',
                operator: 'eq',
                value: filterStdTaglie
            },
            {
                field: 'rifTgBase][id]',
                operator: 'eq',
                value: filterTgBaseTaglie
            }
        ],
        onSearch: (value: any) => {
            // console.log(value);
            return [
                {
                    operator: "or",
                    value: [
                        {
                            field: "codiceRigaStd",
                            operator: "contains",
                            value: value.replaceAll(' ', '%')
                        },
                        {
                            field: "versione",
                            operator: "contains",
                            value: value.replaceAll(' ', '%')
                        },
                        {
                            field: "descRigaStd",
                            operator: "contains",
                            value: value.replaceAll(' ', '%')
                        }
                    ]
                }
            ]
        }
    });

    const options = resultCodici.data?.data.map((item) => ({
        label: item.codiceRigaStd + ' - Ver. ' + item.versione + ' - ' + item.descRigaStd,
        value: item.id,
    }));

    const {selectProps: codiciRigheSelectProps, queryResult: resultRigheCodici} = useSelect<IRigheSchedeTaglie>({
        // pagination: {
        //     pageSize: 100
        // },
        queryOptions: {
            enabled: false
        },
        resource: 'righe-schede-taglies',
        meta: {
            populate: 'rifGruppoTg, rifTgBase, rifMisureRighes, rifMisureRighes.rifTaglie'
        },
        filters: [
            {
                field: 'id',
                operator: 'eq',
                value: filterTg.current
            }
        ],
        optionValue: 'id',
        optionLabel: 'codiceRiga',
    });

    interface DataType {
        id: string;
        name: string;
        age: number;
        address: string;
    }

    const createRigheSchedaTg = useCreate<IRigheSchedeTaglie>();

    const addToCart = (d: any) => {
        // formProps.form?.submit();
        if (formProps.form?.getFieldValue('descScheda') && formProps.form?.getFieldValue('rifDitta')) {
            if (righeSelezionate && righeSelezionate.current.length <= 1) {
                resultCodici.refetch().then( (result: any) => {
                    const righeStd: any = result.data?.data.find((x: any) => x.id === d) || {};
                    console.log(righeStd);
                    righeStd.key = righeStd.id;
                    // IMPOSTO ORDINAMENTO
                    // CREO RIGHE SCHEDA TAGLIE CON ID RIF SCHEDA
                    const getNumeroRiga: any = resultRighe?.data?.data.rifRigheSchedaTg.filter((f: any) => f.id === righeSelezionate.current[0]);
                    console.log(getNumeroRiga);
                    createRigheSchedaTg.mutateAsync({
                        resource: 'righeSchedeTaglieApi_insRiga',
                        successNotification: false,
                        values: {
                            isMultiple: false,
                            idScheda: id,
                            idRigaStd: righeStd.id,
                            idRighes: [],
                            nRiga: (getNumeroRiga && getNumeroRiga.length > 0) ? getNumeroRiga[0]['numeroRiga'] : 0
                        }
                    }).then((res: any) => {
                        console.log(res);
                        if (res && res.data && res.data.success) {
                            //formProps.form?.submit();
                            // window.location.reload();
                            // resultRighe?.refetch();
                            aggiornaRighe();
                        } else {
                            message.error('Errore Creazione Riga');
                        }

                    })
                });
            } else {
                message.warning('Sono Selezionate più di una riga - non posso determinare il punto di inserimento');
            }
        }
    }

    const updateMisureRighe = useUpdate();
    const checkRighe = useCreate();
    const [selectedRowRigheKeys, setSelectedRowRigheKeys] = useState<React.Key[]>([]);
    const selectedRowRighe = useRef<any>([]);

    const modificaRighe = useCreate();
    const confermaModificaRighe = () => {
        console.log("Eseguo Modifica Misure su Righe Selezionate: ", selectedRowRighe.current);
        modificaRighe.mutateAsync({
            resource: 'modificaMisureRiga',
            successNotification: false,
            values: selectedRowRighe.current
            }
        ).then((res: any) => {

        })

    }
    const cancelModalRighe = () => {
        console.log("Operazione Annullata");
    }

    const onSelectChangeRighe = (newSelectedRowRigheKeys: React.Key[], selectedRow: DataType[]) => {
        console.log('selectedRowRigheKeys changed: ', selectedRow);
        selectedRowRighe.current = selectedRow;
        setSelectedRowRigheKeys(newSelectedRowRigheKeys);
    };
    const rowSelectionRighe = {
        selectedRowRigheKeys,
        onChange: onSelectChangeRighe,
    };

    const onChangeTg = (record: any, cellId: number, rowId: number, scala: number, d: any, value: string, oldValue: any) => {
        // console.log('CAMBIO: ', record);
        // console.log(cellId, rowId, value, d);
        //setRigheM([]);
        const realScala = parseFloat(value) - parseFloat(oldValue);
        // console.log(realScala);
        const indexBase = d.findIndex((x: any) => x.rifTaglie.descTg === formProps.form?.getFieldValue('rifTgBase')['descTg']);
        const indexMod = d.findIndex((x: any) => x.id === cellId);
        const indexRigaM = righeN.current.findIndex((x: any) => x.id === rowId );
        const nomeCellaOri = d[indexMod].rifTaglie.descTg;
        // console.log(indexMod, indexBase, nomeCellaOri);
        if (indexMod < indexBase) {
            let v = 0;
            // @ts-ignore
            righeN.current[indexRigaM][nomeCellaOri] = parseFloat(value).toFixed(1);
            for (let i = indexMod - 1; i >= 0; i--) {
                v++;
                const nomeCella = d[i].rifTaglie.descTg;
                const old = righeN.current[indexRigaM][nomeCella];
                // const old = d[indexRigaM][nomeCella];
                // console.log(old);
                const newValue = parseFloat(old) + parseFloat(realScala.toString());
                // console.log(d[i].misura, d[i + 1].misura, scala, Number(value) - (Number(realScala) * v));
                // console.log(newValue);
                // @ts-ignore
                righeN.current[indexRigaM][nomeCella] = newValue;
            }
        }
        if (indexMod > indexBase) {
            let v = 0;
            // @ts-ignore
            righeN.current[indexRigaM][nomeCellaOri] = parseFloat(value).toFixed(1);
            for (let i = indexMod + 1; i <= d.length -1; i++) {
                v++;
                const nomeCella = d[i].rifTaglie.descTg;
                const old = righeN.current[indexRigaM][nomeCella];
                // const old = d[indexRigaM][nomeCella];
                // console.log(old);
                const newValue = parseFloat(old) + parseFloat(realScala.toString());
                // console.log(d[i].misura, value, i, nomeCella, Number(Number(value) + (Number(scala) * v)).toFixed(1));
                // @ts-ignore
                righeN.current[indexRigaM][nomeCella] = newValue;
            }
        }
        if (indexMod === indexBase) {
            let v = 0;
            // @ts-ignore
            righeN.current[indexRigaM][nomeCellaOri] = parseFloat(value).toFixed(1);
            for (let i = indexMod - 1; i >= 0; i--) {
                v++;
                const nomeCella = d[i].rifTaglie.descTg;
                const old = righeN.current[indexRigaM][nomeCella];
                // const old = d[indexRigaM][nomeCella];
                const newValue = parseFloat(old) + parseFloat(realScala.toString());
                // @ts-ignore
                righeN.current[indexRigaM][nomeCella] = newValue;
            }
            let y = 0;
            for (let i = indexMod + 1; i <= d.length -1; i++) {
                y++;
                const nomeCella = d[i].rifTaglie.descTg;
                const old = righeN.current[indexRigaM][nomeCella];
                // const old = d[indexRigaM][nomeCella];
                const newValue = parseFloat(old) + parseFloat(realScala.toString());
                // @ts-ignore
                righeN.current[indexRigaM][nomeCella] = newValue;
            }
        }
        // console.log('MISURE DA MODIFICARE: ', righeN.current[indexRigaM]);
        for (const [index, misura] of righeN.current[indexRigaM]['rifMisureRighes'].entries()) {
            const misuraCambiata = (Number(misura.misuraOriginale).toFixed(1) !== Number(righeN.current[indexRigaM][misura.rifTaglie.descTg]).toFixed(1));
            const versioneSuccessive = (resultRighe && resultRighe.data && resultRighe.data.data && resultRighe.data.data.versione && resultRighe.data.data.versione > 1);
            const newIsChanged = (versioneSuccessive && misuraCambiata);
            console.log(parseFloat(righeN.current[indexRigaM][misura.rifTaglie.descTg]).toFixed(1));
            // console.log(index);
            // console.log(misura.misuraOriginale);
            // console.log(righeN.current[indexRigaM][misura.rifTaglie.descTg]);
            // console.log(misura.id);
            // console.log(resultRighe?.data?.data.versione);
            if (misuraCambiata || (newIsChanged !== righeN.current[indexRigaM]['isCambiata'])) {
                // console.log('CAMBIATA: ', Number(misura.misuraOriginale).toFixed(1), Number(righeN.current[indexRigaM][misura.rifTaglie.descTg]).toFixed(1))
                updateMisureRighe.mutateAsync({
                    resource: 'misure-righes',
                    successNotification: false,
                    id: misura.id,
                    values: {
                        min: parseFloat(righeN.current[indexRigaM][misura.rifTaglie.descTg]) - parseFloat(righeN.current[indexRigaM]['tolleranzaRiga']),
                        max: parseFloat(righeN.current[indexRigaM][misura.rifTaglie.descTg]) + parseFloat(righeN.current[indexRigaM]['tolleranzaRiga']),
                        misura: parseFloat(righeN.current[indexRigaM][misura.rifTaglie.descTg]).toFixed(1),
                        isCambiata: newIsChanged
                    }
                }).then( res => {
                    // message.info('Taglie Aggiornate');
                    // console.log(res.data);

                })
            }
        }
        // CONTROLLO ESISTENZA RIGA SU ALTRE SCHEDE
        // checkRighe.mutateAsync({
        //     resource: 'checkRigheSchede',
        //     successNotification: false,
        //     values: {
        //         idScheda: id,
        //         idRiga: rowId,
        //         versioneRiga: record.versione,
        //         codiceRiga: record.codiceRiga,
        //         stagioni: resultRighe?.data?.data.rifStagione.nomeStagione
        //     }
        // }).then((res: any) => {
        //     if (res && res.data && res.data.length > 0) {
        //         res.data.map((r: any) => (r.origineIdRiga = rowId));
        //         Modal.confirm({
        //             width: '70%',
        //             title: 'Su quali Schede vuoi modificare le misure?',
        //             content:
        //                 <Table
        //                     rowSelection={rowSelectionRighe}
        //                     pagination={false}
        //                     loading={res.data.length === 0}
        //                     key={'idRiga'}
        //                     size={'small'}
        //                     dataSource={res.data}>
        //                     <Table.Column
        //                         width={100}
        //                         title={'Nome Scheda'}
        //                         dataIndex={'nomeScheda'}
        //                         key={'nomeScheda'}
        //                     />
        //                     <Table.Column
        //                         width={300}
        //                         ellipsis={true}
        //                         title={'Descrizione Scheda'}
        //                         dataIndex={'descScheda'}
        //                         key={'descScheda'}
        //                     />
        //                     <Table.Column
        //                         width={150}
        //                         title={'Codice Riga'}
        //                         dataIndex={'codRiga'}
        //                         key={'codRiga'}
        //                     />
        //                 </Table>,
        //             okText: 'Conferma',
        //             cancelText: 'Annulla',
        //             onOk: confermaModificaRighe,
        //             onCancel: cancelModalRighe
        //         });
        //     }
        // })
        message.info('Taglie Aggiornate');
        // const i = righeN.current[indexRigaM]['rifMisureRighes'].find((x: any) => x.rifTaglie.descTg === item.rifTaglie.descTg);
        // console.log('MISURE NON MODIFICATE: ', righeN.current[indexRigaM]['rifMisureRighes']);
        setTimeout(() => {
            // setRigheM(righeN.current);
            // window.location.reload();
            // resultRighe?.refetch();
            aggiornaRighe();
        }, 500)
    }

    interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
        'data-row-key': number;
    }

    const RowS = ({ children, ...props }: RowProps) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });

        const style: React.CSSProperties = {
            ...props.style,
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
                /translate3d\(([^,]+),/,
                'translate3d(0,',
            ),
            transition,
            ...(isDragging ? { position: 'relative', zIndex: 1 } : {}),
        };

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child: any) => {
                    if ((child as React.ReactElement).key === 'sort') {
                        return React.cloneElement(child as React.ReactElement, {
                            children: (
                                <Icons.MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{ touchAction: 'none', cursor: 'move'}}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const onDragEnd = async ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            const activeIndex = righeM.findIndex((i: any) => i.id === active.id);
            const overIndex = righeM.findIndex((i: any) => i.id === over?.id);
            const newArray: any = arrayMove(righeM, activeIndex, overIndex);
            setRigheM((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.id === active.id);
                const overIndex = prev.findIndex((i: any) => i.id === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
            // righeN.current = [...]
            // CREO METODO ASYNC PER UPDATE RIGHE
            for (let [i, r] of newArray.entries()) {
                r.numeroRiga = i + 1;
            }
            // setRigheM([...newArray]);
            updateRighe.mutateAsync({
                resource: 'righeSchedeTaglieApi_updateRiga',
                successNotification: false,
                values: {
                    data: newArray,
                    type: 'drag'
                }
            }).then(res => {
                console.log(res);
                if (res && res.data && res.data.success) {
                    aggiornaRighe();
                    console.log('Eseguo Refetch')
                    setLoadOrdinamento(false);
                    message.success('Riordinamento Eseguito Correttamente')
                }
            })
            // setRigheM(newArray);
        } else {
            console.log('NON RIORDINO')
        }
    };

    const [doc, setDoc] = useState<any[]>([]);
    const [img, setImg] = useState<any[]>([]);
    const [fot, setFot] = useState<any[]>([]);

    const updateSchedeTaglie = useUpdate<ISchedeTaglie>();

    const daControllare = () => {
        if (formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isAperta || formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isControllata) {
            const statoScheda: any = statoSchedaResult.data?.data.find((x: any) => x.isDaControllare);
            formProps.form?.setFieldsValue(
                {'rifStatoSchedaTaglie': {0 : {id: statoScheda.id}}}
            );
            const sottoVersione = Number(formProps.form?.getFieldValue('sottoVersione')) + 1;
            formProps.form?.setFieldValue('sottoVersione', sottoVersione);
            formProps.form?.setFieldValue('strVersione', formProps.form?.getFieldValue('versione').toString() + '.' + formProps.form?.getFieldValue('sottoVersione').toString())
        } else {
            const statoScheda: any = statoSchedaResult.data?.data.find((x: any) => x.isControllata);
            formProps.form?.setFieldsValue(
                {'rifStatoSchedaTaglie': {0 : {id: statoScheda.id}}}
            );
        }
        formProps.form?.submit();
    }

    const pubblica = () => {
        setLoadingPubblic(true);
        if (formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isPubblicata) {
            const versione = -1;
            cloneRecord(resultRighe?.data?.data, versione, true).then(res => {});
            setLoadingPubblic(false);
        } else {
            const statoScheda: any = statoSchedaResult.data?.data.find((x: any) => x.isPubblicata);
            // console.log([statoScheda]);
            formProps.form?.setFieldsValue(
                    {'rifStatoSchedaTaglie': {0 : {id: statoScheda.id}}}
            );
            // console.log(formProps.form?.getFieldValue('rifStatoSchedaTaglie'));
            setLoadingPubblic(false);
            formProps.form?.submit();
        }
    }
    const onSave = (values: any) => {
        setLoadingSave(true)
            // APERTA
            let fileDocs = [];
            let fileImgs = [];
            let fileFots = [];
            if (doc) {
                for (let d of doc) {
                    fileDocs.push(d);
                }
            }
            if (img) {
                for (let i of img) {
                    fileImgs.push(i);
                }
            }
            if (fot) {
                for (let i of fot) {
                    fileFots.push(i);
                }
            }
            values.descScheda = values.descScheda.toUpperCase();
            values.nomeScheda = values.nomeScheda.toUpperCase();
            values.note = values.note?.toUpperCase();
            values.figurino = fileImgs;
            values.allegati = fileDocs;
            values.foto = fileFots;
            values.updatedAt = new Date().toLocaleString();
            values.rifStatoSchedaTaglie = {id: values.rifStatoSchedaTaglie[0].id}
            // console.log(filterTg.current);
            // console.log(filterTg.current.length);
            // console.log(values);
        if (filterTg.current && filterTg.current.length > 0) {
            values.rifRigheSchedaTg = filterTg.current;
            updateSchedeTaglie.mutateAsync({
                resource: 'schede-taglies',
                successNotification: false,
                id: Number(id),
                values: values
            }).then( r => {
                // console.log(r);
                setLoadingSave(false);
                message.success('Scheda Salvata!')
            })
        } else {
            updateSchedeTaglie.mutateAsync({
                resource: 'schede-taglies',
                successNotification: false,
                id: Number(id),
                values: values
            }).then( r => {
                // console.log(r);
                setLoadingSave(false);
                message.success('Scheda Salvata!')
            })
            // message.error('Si è verificato un Errore - Fai il Reload della Pagina!')
        }

    }

    const props: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + '/api/upload',
        onChange(info) {
            // console.log(formProps.form)
            // console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                setDoc([...doc, ...info.file.response]);
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const propsImg: UploadProps = {
        name: 'files',
        multiple: false,
        action: API_URL + '/api/upload',
        onChange(info) {
            // console.log(formProps.form)
            // console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                setImg(info.file.response);
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const propsFot: UploadProps = {
        name: 'files',
        multiple: false,
        action: API_URL + '/api/upload',
        onChange(info) {
            // console.log(formProps.form)
            // console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                setFot(info.file.response);
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const deleteRigaSchedaTg = useDelete<IRigheSchedeTaglie>();
    const deleteRigheMultiple = useCreate<IRigheSchedeTaglie>();
    const eliminaSelezionati = () => {
        console.log('Righe Da Eliminare: ', righeSelezionate.current);
        if (resultRighe && resultRighe.data?.data.rifStatoSchedaTaglie[0].isPubblicata) {
            message.warning('Non puoi eliminare se la scheda è Pubblicata! Devi prima Aprire la scheda e poi fare tutte le modifiche necessarie.');
        } else {
            if (righeSelezionate && righeSelezionate.current && righeSelezionate.current.length > 0) {
                deleteRigheMultiple.mutateAsync({
                    resource: 'deleteRigheMultiple',
                    values: {
                        data: righeSelezionate.current,
                        idScheda: id
                    }
                }).then(res => {
                    console.log(res);
                    // window.location.reload();
                    // resultRighe?.refetch();
                    aggiornaRighe();
                    righeSelezionate.current = [];
                })
            } else {
                message.warning('Non hai selezionato le righe da eliminare!');
            }
        }

    }

    const deleteRow = (idriga: number) => {
        console.log('Righe Da Eliminare: ', righeSelezionate.current);
        if (resultRighe && resultRighe.data?.data.rifStatoSchedaTaglie[0].isPubblicata) {
            message.warning('Non puoi eliminare se la scheda è Pubblicata!');
        } else {
            if (idriga) {
                deleteRigheMultiple.mutateAsync({
                    resource: 'deleteRigheMultiple',
                    values: {
                        data: [idriga],
                        idScheda: id
                    }
                }).then(res => {
                    console.log(res);
                    // window.location.reload();
                    // resultRighe?.refetch();
                    aggiornaRighe();
                })
            } else {
                message.warning('Non hai selezionato le righe da eliminare!');
            }
        }

    }
    // const deleteRow = (id: number) => {
    //     // console.log(righeM);
    //     // console.log(id, filterTg, righeN, righeM);
    //     // setFilterTaglie((cur: any) => [...filterTaglie.filter((x: any) => x !== id)]);
    //     filterTg.current = filterTg.current.filter((x: any) => x !== id);
    //     // console.log(righeM);
    //     // console.log(id);
    //     const filterRiga = righeM.filter((r: any) => r.id === id);
    //     // console.log(filterRiga)
    //     const numeroRiga = filterRiga[0]?.numeroRiga;
    //     const newArr: any = righeN.current;
    //     const index = newArr.findIndex((item: any) => item.id === id);
    //     if (index > -1) {
    //         newArr.splice(index, 1);
    //         // console.log('Numero Riga: ', numeroRiga);
    //         newArr.map((m: any) => {
    //             if (m.numeroRiga > numeroRiga ) {
    //                 m.numeroRiga--;
    //                 // m.letteraRiga = String.fromCharCode(m.letteraRiga.charCodeAt(0) - 1);
    //                 updateMisureRighe.mutateAsync({
    //                     resource: 'righe-schede-taglies',
    //                     successNotification: false,
    //                     id: m.id,
    //                     values: {
    //                         numeroRiga: m.numeroRiga,
    //                         // letteraRiga: m.letteraRiga
    //                     }
    //                 }).then( res => {
    //                     // message.info('Taglia Aggiornata');
    //                     // console.log(res.data);
    //                 })
    //             }
    //         });
    //         setRigheM([...newArr]);
    //         // console.log(newArr);
    //         setTimeout(() => {
    //             deleteRigaSchedaTg.mutateAsync({
    //                 resource: 'righeSchedeTaglieApi_delRiga',
    //                 id: id
    //             }).then( res => {
    //                 formProps.form?.submit();
    //             });
    //         }, 300)
    //     }
    // }

    const copiaSchede = () => {
        console.log('Schede Selezionate: ', righeSelezionate.current);
        if (righeSelezionate && righeSelezionate.current && righeSelezionate.current.length > 0) {
            localStorage.setItem('schedeCopiate', righeSelezionate.current);
            list('schede-taglies');
        } else {
            message.warning('Non hai selezionato nessuna riga - Impossibile Copiare!')
        }

    }

    const incollaSchede = () => {
        // console.log('Schede Selezionate: ', righeSelezionate.current);
        setLoadingPaste(true);
        if (localStorage.getItem('schedeCopiate')) {
            const getNumeroRiga: any = resultRighe?.data?.data.rifRigheSchedaTg.filter((f: any) => f.id === righeSelezionate.current[0]);
            if (righeSelezionate && righeSelezionate.current && righeSelezionate.current.length > 0) {
                createRigheSchedaTg.mutateAsync({
                    resource: 'righeSchedeTaglieApi_insRiga',
                    successNotification: false,
                    values: {
                        isMultiple: true,
                        idScheda: id,
                        idRigaStd: null,
                        idRighes: localStorage.getItem('schedeCopiate'),
                        nRiga: (righeSelezionate.current && righeSelezionate.current.length > 0) ? getNumeroRiga[0]['numeroRiga'] : 0
                    }
                }).then((res: any) => {
                    console.log(res);
                    if (res && res.data && res.data.success) {
                        localStorage.removeItem('schedeCopiate');
                        setLoadingPaste(false);
                        // window.location.reload();
                        // resultRighe?.refetch();
                        aggiornaRighe();
                    } else {
                        message.error('Errore Creazione Riga');
                        setLoadingPaste(false);
                    }
                })
            } else {
                message.warning('Devi selezionare il punto dove inserire le Righe Copiate');
                setLoadingPaste(false);
            }
        } else {
            message.warning('Non sono presenti righe da Incollare');
            setLoadingPaste(false);
        }

    }

    const updateRighe = useCreate();
    const [loadOrdinamento, setLoadOrdinamento] = useState(false);
    const ordinamentoLet = async () => {
        setLoadOrdinamento(true);
        // console.log(righeM);
        // console.log(formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isPubblicata)
        if (formProps.form?.getFieldValue('rifStatoSchedaTaglie')[0].isPubblicata) {
           message.warning('Non puoi più effettuare l\'ordinamento Alfabetico.');
        } else {
            righeM.sort((s: any) => s.numeroRiga).map((ord: any, index: number) => {
                const n = Math.floor((index) / 26);
                const c = String.fromCharCode(((index) % 26) + 65)
                ord.letteraRiga = (n > 0 ? c + '' + n : c);
            })
            setRigheM([...righeM]);
            updateRighe.mutateAsync({
                resource: 'righeSchedeTaglieApi_updateRiga',
                successNotification: false,
                values: {
                    data: righeM,
                    type: 'ordinamento'
                }
            }).then(res => {
                // console.log(res);
                    aggiornaRighe();
                    setLoadOrdinamento(false);
                    message.success('Ordinamento Eseguito Correttamente')
            })
            // for (const r of righeM)  {
            //     await delay(100);
            //     await updateRiga(r, r.numeroRiga)
            // }
            // console.log(righeM);
        }
    }

    const createSchedaTaglie = useCreate<ISchedeTaglie>();

    const { list, edit } = useNavigation();

    const cloneRecord = async (e: any, versione?: any, isNomeScheda?: boolean) => {
        setLoadingClone(true);
        const statoScheda: any = statoSchedaResult.data?.data.find((x: any) => x.isAperta);
        console.log(e);
        createSchedaTaglie.mutateAsync({
            resource: 'clonaSchede',
            values: {
                data: e,
                versione: versione,
                isNomeScheda: isNomeScheda,
                statoScheda: statoScheda
            }
        }).then((res: any) => {
            console.log(res);
            message.info('Scheda Clonata');
            edit('schede-taglies', res.data.data.id);
            setLoadingClone(false);
            window.location.reload();
        })
    }

    const setVersione = () => {
        // console.log('ON CHANGE');
        formProps.form?.setFieldValue('strVersione', formProps.form?.getFieldValue('versione').toString() + '.' + formProps.form?.getFieldValue('sottoVersione').toString())
    }

    const righeSelezionate = useRef<any>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        righeSelezionate.current = newSelectedRowKeys;
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Dati Generali',
            children: (<>
                <Row gutter={24} >
                    <Col span={4}>
                        <Form.Item
                            label="Nome Scheda"
                            name="nomeScheda"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} placeholder={'Inserisci Nome Scheda...'} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            hidden={true}
                            label="Ver."
                            name="versione"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input readOnly={true} defaultValue={0} onChange={setVersione}/>
                        </Form.Item>
                        <Form.Item
                            hidden={true}
                            label="Sotto Ver."
                            name="sottoVersione"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input readOnly={true} defaultValue={0} onChange={setVersione}/>
                        </Form.Item>
                        <Form.Item
                            label="Ver."
                            name="strVersione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input readOnly={true} defaultValue={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data"
                            name="dataCreazione"
                            getValueProps={(value) => {
                                return ({value: value !== null ? value && value.hasOwnProperty('$D') ? value : dayjs(value) : ''})
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Stato"
                            name={["rifStatoSchedaTaglie", "0", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                disabled={false} {...statoSchedeSelectProps}/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Stagione"
                            name={["rifStagione", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...stagioniSchedeSelectProps}/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Anno"
                            name={["rifAnno", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...annoSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Gruppo Taglie"
                            name={["rifGruppoTaglie", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select disabled={resultRighe && resultRighe.data?.data.rifStatoSchedaTaglie[0].isPubblicata} {...gruppoTaglieSelectProps} onSelect={(e) => getTaglie(e, true)}/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Taglia Base"
                            name={["rifTgBase", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select disabled={resultRighe && resultRighe.data?.data.rifStatoSchedaTaglie[0].isPubblicata} options={taglie} onSelect={(e) => setFilterTgBaseTaglie(e)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={20}>
                        <Form.Item
                            label="Descrizione Scheda"
                            name="descScheda"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Fornitore"
                            name={["rifDitta", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...ditteSelectProps} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={15}>
                        <Form.Item
                            label="Note"
                            name="note"
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={2} style={{
                        textAlign: 'left',
                    }}>
                        <Form.Item
                            label={' '}
                            name={'ordinamento'} >
                            <Button type={'primary'} htmlType={'button'} style={{
                                backgroundColor: 'coral',
                                border: 'none',
                                width: '100%'
                            }} onClick={copiaSchede}>Copia</Button>
                        </Form.Item>

                    </Col>
                    <Col span={2} style={{
                        textAlign: 'left',
                    }}>
                        <Form.Item
                            label={' '}
                            name={'ordinamento'} >
                            <Button type={'primary'} htmlType={'button'} style={{
                                backgroundColor: 'blueviolet',
                                border: 'none',
                                width: '100%'
                            }} onClick={ordinamentoLet} loading={loadOrdinamento}>Ordina</Button>
                        </Form.Item>

                    </Col>
                    <Col span={2} style={{
                        textAlign: 'right'
                    }}>
                        <Form.Item
                            label={' '}
                            name={'clona'} >
                        <Tooltip placement="top" title={'Clona'}>
                            <Button
                                style={{
                                    backgroundColor: '#333333',
                                    border: 'none',
                                    width: '100%'
                                }}
                                type={'primary'}
                                loading={loadingClone}
                                onClick={() => cloneRecord(resultRighe?.data?.data)}
                            >
                                Clona
                            </Button>
                        </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col span={2} style={{
                        textAlign: 'right'
                    }}>
                        <Form.Item
                            label={' '}
                            name={'print'} >
                            <Tooltip placement="top" title={'Print'}>
                                <Popconfirm
                                    title="Attenzione"
                                    description="Vuoi stampare le schede di oontrollo?"
                                    okText="Si"
                                    cancelText="No"
                                    onCancel={() => printPdf()}
                                    onConfirm={() => printPdf(true)}
                                >
                                    <Button
                                        loading={loadingSave}
                                        style={{
                                            backgroundColor: '#9b0000',
                                            border: 'none',
                                            width: '100%'
                                        }}
                                        type={'primary'}
                                    >
                                        Print
                                    </Button>
                                </Popconfirm>
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={24}>
                    <Col span={15} style={{
                        textAlign: 'left',
                    }}>
                        <Select
                            disabled={resultRighe && resultRighe.data?.data.rifStatoSchedaTaglie[0].isPubblicata}
                            allowClear={true}
                            placeholder={'Seleziona Riga...'}
                            style={{
                                width: '100%'
                            }}
                            loading={resultCodici.data?.data.length === 0}
                            {...codiciSelectProps}
                            options={options}
                            onSelect={(d) => {addToCart(d)}}/>
                    </Col>
                    <Col span={2} style={{
                        textAlign: 'right',
                    }}>
                            <Button type={'primary'}
                                    htmlType={'button'} style={{
                                backgroundColor: 'lightsteelblue',
                                border: 'none',
                                width: '100%'
                            }} onClick={incollaSchede} loading={loadingPaste}>Incolla</Button>
                    </Col>
                    <Col span={2} style={{
                        textAlign: 'right'
                    }}>
                        <Button type={'primary'}
                                hidden={loadOrdinamento}
                                loading={loadingSave}
                                htmlType={'submit'} style={{
                            backgroundColor: '#fdb82a',
                            border: 'none',
                            width: '100%'
                        }}>Salva</Button>
                    </Col>
                    <Col span={2} style={{
                        textAlign: 'right'
                    }}>
                        <Button type={'primary'} htmlType={'button'} style={{
                            backgroundColor: '#029f28',
                            border: 'none',
                            width: '100%'
                        }} onClick={daControllare}>{(formProps.form?.getFieldValue('rifStatoSchedaTaglie')?.[0]?.isAperta ||
                            formProps.form?.getFieldValue('rifStatoSchedaTaglie')?.[0]?.isControllata) ? 'Da Controllare' : 'Controllata'}</Button>
                    </Col>
                    <Col span={2} style={{
                        textAlign: 'right'
                    }}>
                        <Button style={{
                            width: '100%'
                        }} type={'primary'} loading={loadingPubblic} htmlType={'button'} onClick={pubblica}>{(formProps.form?.getFieldValue('rifStatoSchedaTaglie')?.[0]?.isAperta ||
                            formProps.form?.getFieldValue('rifStatoSchedaTaglie')?.[0]?.isDaControllare ||
                            formProps.form?.getFieldValue('rifStatoSchedaTaglie')?.[0]?.isControllata) ? 'Pubblica' : 'Apri'}</Button>
                    </Col>
                </Row>
                <hr/>
                <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                        // rowKey array
                        items={righeM?.map((i: any) => i?.key)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table
                            id={'pdfPrint'}
                            style={{
                                zIndex: -1
                            }}
                            rowSelection={rowSelection}
                            scroll={{y: window.innerHeight - 450}}
                            size={'small'}
                            sticky={true}
                            bordered={true}
                            loading={loading}
                            components={{
                                body: {
                                    row: RowS,
                                },
                            }}
                            rowKey="key"
                            pagination={false}
                            dataSource={righeM}
                            columns={columns}>
                        </Table>
                    </SortableContext>
                </DndContext>
            </>),
        },
        {
            key: '2',
            label: 'Upload',
            children: (<>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="figurino"
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            noStyle
                        >
                            <Row gutter={24}>
                                <Col span={3} style={{height: 110}}>
                                    <Dragger {...propsImg}
                                             showUploadList={false}
                                             style={{
                                                 background: '#74d9e2',
                                                 border: 'none'
                                             }}
                                             multiple={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icons.FileAddOutlined style={{
                                                color: '#000000'
                                            }}/>
                                        </p>
                                        {/*<p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                                        <p className="ant-upload-hint" style={{fontSize: 12}}>
                                            Figurino
                                        </p>
                                    </Dragger>
                                </Col>
                                <Col span={21}>
                                    <Image.PreviewGroup
                                        preview={{
                                            onVisibleChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                        {
                                            img && img.length > 0 &&
                                            img.map( (im: any) => {
                                                return <Image style={{borderRadius: 15, width: '100%', height: '100%'}} src={API_URL + '' + im.url} />
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="foto"
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            noStyle
                        >
                            <Row gutter={24}>
                                <Col span={3} style={{height: 110}}>
                                    <Dragger {...propsFot}
                                             showUploadList={false}
                                             style={{
                                                 background: '#68e895',
                                                 border: 'none'
                                             }}
                                             multiple={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icons.FileImageOutlined style={{
                                                color: '#000000'
                                            }}/>
                                        </p>
                                        {/*<p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                                        <p className="ant-upload-hint" style={{fontSize: 12}}>
                                            Foto
                                        </p>
                                    </Dragger>
                                </Col>
                                <Col span={18}>
                                    <Image.PreviewGroup
                                        preview={{
                                            onVisibleChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                        {
                                            fot && fot.length > 0 &&
                                            fot.map( (im: any) => {
                                                return <Image style={{borderRadius: 15, width: 300, height: 200}} src={API_URL + '' + im.url} />
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="allegati"
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            noStyle
                        >
                            <Row gutter={24}>
                                <Col span={3} style={{height: 110}}>
                                    <Dragger {...props}
                                             showUploadList={false}
                                             style={{
                                                 background: '#e7cb70',
                                                 border: 'none'
                                             }}
                                             multiple={true}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icons.FileWordOutlined style={{
                                                color: '#000000'
                                            }}/>
                                        </p>
                                        {/*<p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                                        <p className="ant-upload-hint" style={{fontSize: 12}}>
                                            File
                                        </p>
                                    </Dragger>
                                </Col>
                                <Col span={18}>
                                    <Image.PreviewGroup
                                        preview={{
                                            onVisibleChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                        {
                                            doc && doc.length > 0 &&
                                            doc.map( (im: any) => {
                                                // console.log(im);
                                                return <><a href={API_URL + '' + im.url} target={'_blank'}>{im.name}</a><br /></>
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            </>),
        }
    ];

    const printPdf = (stampaControllo?: boolean) => {
        const newRighe: any = [];
        righeM.map((m: any) => {
            taglie.map((t: any) => {
                const findMisuraOriginale = m.rifMisureRighes.filter((x: any) => x.rifTaglie.descTg === t.label);
                m[t.label] = (m[t.label].toString().trim().length === 3 || m[t.label].toString().trim().length === 1) ? "  " + Number(m[t.label]).toFixed(1) : Number(m[t.label]).toFixed(1);
                if (stampaControllo) {
                    if (findMisuraOriginale && findMisuraOriginale.length > 0) {
                        if (findMisuraOriginale[0].misura !== findMisuraOriginale[0].misuraOriginale) {
                            m[t.label] = findMisuraOriginale[0].misura.toString() + ' / ' + findMisuraOriginale[0].misuraOriginale.toString()
                        }
                    }
                }
            })
            m.tolleranzaRiga = Number(m.tolleranzaRiga).toFixed(1);
            newRighe.push(
                m
            )
        });
        const newCol = columns.filter((f: any) => {
            if (f.dataIndex === 'actions') {
                f.title = ''
            }
            return (
            f.dataIndex !== 'isBanda' &&
            f.key !== 'sort' &&
            f.dataIndex !== 'isTaglioRif' &&
            f.dataIndex !== 'posizioneTgBase' &&
            f.dataIndex !== 'scalaTg' &&
            f.dataIndex !== 'lBandaTgBase')}
        );
        const doc: any = new jsPDF({
            orientation: "landscape"
        });

        // console.log(newCol);
        const header =  (data: any) => {
            doc.setFontSize(10);
            doc.text("GEBEL SRL - VIA DEGLI ALPINI, SN - 65029 TORRE DEI PASSERI (PE) - P.IVA 001114900689", 20, 20);
            doc.text("ACCEPTED AND SIGNED", 230, 20);
            doc.setFont("helvetica", "bold");
            doc.text("ART: " + resultRighe?.data?.data.nomeScheda + " " + resultRighe?.data?.data.descScheda , 20, 26);
            doc.text("VER: " + resultRighe?.data?.data.versione, 20, 32);
            doc.setFont("helvetica", "normal");
            doc.text("DATE: " + dayjs(resultRighe?.data?.data.dataCreazione).format('DD-MM-YYYY') , 185, 20);
            doc.text("NOTES: " + resultRighe?.data?.data.note, 20, 38);
            doc.text("SIZE MEASUREMENTS BEFORE WASHING", 20, 45);
            };
            autoTable(doc, {
                columns: newCol,
                body: newRighe,
                theme: 'grid',
                headStyles: { halign: 'left'},
                columnStyles: { descrizioneRiga: { halign: 'left', overflow: 'ellipsize' }, actions: { cellWidth: 15 } },
                styles: {fontSize: 7, cellPadding: 1, halign: 'right', lineColor: '#363636' },
                margin: {top: 50},
                didDrawPage: header,
                didParseCell: (data: any) => {
                    // console.log(data);
                    if (data.section === 'body') {
                        data.row.raw['rifMisureRighes'].map((res: any) => {
                            if (res.rifTaglie.descTg === data.column.dataKey) {
                                data.cell.styles.cellWidth = 10
                                if (res.isCambiata) {
                                    data.cell.styles.fillColor = '#009bdc';
                                    data.cell.styles.textColor = '#FFFFFF'
                                }
                            }
                        })
                    }
                },

            })
            doc.save(resultRighe?.data?.data.nomeScheda + " " + resultRighe?.data?.data.descScheda + ".pdf");
    };

    return (
        <Edit saveButtonProps={saveButtonProps}
              footerButtons={
            <>

            </>
        }>
            <Form {...formProps} layout="vertical" onFinish={onSave}>
                <Tabs defaultActiveKey="1" items={items} style={{
                    marginTop: -30
                }}/>
            </Form>
        </Edit>
    );
};
