import { useForm, Edit } from "@refinedev/antd";
import { Form, Input, Table, Switch } from "antd";
import React, {useEffect} from 'react';
import {IDitte} from '../../interfaces';

interface sedi {
    label: string;
    value: number;
}
export const DitteEdit: React.FC = () => {
    const { formProps, saveButtonProps, formLoading } = useForm<IDitte>({
        meta: {
            populate: "*",
        },
    });


    useEffect( () => {

    }, [formLoading])


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Descrizione Ditta"
                    name="descDitta"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="emailDitta"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Telefono"
                    name="telDitta"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="is Gebel"
                    name="isGebel"
                    valuePropName={'checked'}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Edit>
    );
};
