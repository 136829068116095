import {
    List,
    TagField,
    useTable,
    CreateButton,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Table, Space, Button, Form, Input, Modal, Popconfirm, message} from 'antd';

import {IMisureRigheStd, IRigheStandardSchedeTaglie, ISchedeTaglie} from 'interfaces';
import React, {useEffect, useState} from 'react';
import {useCreate, useCreateMany, useDelete, useList, useNavigation, useOne} from '@refinedev/core';
import {FRONT_URL} from '../../constants';

export const RigheStandardSchedaTaglieList: React.FC = () => {
    const { tableProps, setFilters, searchFormProps, tableQueryResult } = useTable<IRigheStandardSchedeTaglie>({
        resource: 'getRigheStdSchedaTaglie',
        meta: {
            populate: "rifGruppoTg",
            //populate: "rifGruppoTg, rifTgBase, rifMisureRigheStds, rifMisureRigheStds.rifTaglie",
        },
        onSearch: (values: any) => {
            console.log(values);
            return [
                        {
                            field: "cerca",
                            operator: "contains",
                            value: values.description.replaceAll(' ', '%')
                        },
            ];
        },

        pagination: {
            pageSize: 25,
            mode: 'server'
        },
        sorters:{
            permanent: [
                {
                    field: 'codiceRigaStd',
                    order: 'desc'
                }
            ]
        }
    });

    const [idRigaSchedaTg, setIdRigaSchedaTg] = useState('');
    const [versioneRiga, setVersioneRiga] = useState('');
    // const { refetch} = useList<ISchedeTaglie>({
    //     resource: 'schede-taglies',
    //
    //     queryOptions: {
    //         enabled: false
    //     },
    //
    //     meta: {
    //         populate: 'rifRigheSchedaTg'
    //     },
    //
    //     filters: [
    //         {
    //             operator: 'and',
    //             value: [
    //                 {
    //                     field: 'rifRigheSchedaTg][codiceRiga]',
    //                     operator: 'contains',
    //                     value: idRigaSchedaTg
    //                 },
    //                 {
    //                     field: 'rifRigheSchedaTg][versione]',
    //                     operator: 'contains',
    //                     value: versioneRiga
    //                 }
    //             ]
    //         }
    //     ],
    // });

    const { refetch: checkRigheStd} = useList<ISchedeTaglie>({
        resource: 'schede-taglies',

        queryOptions: {
            enabled: false
        },

        meta: {
            populate: 'rifRigheSchedaTg'
        },

        filters: [
            {
                field: 'rifRigheSchedaTg][codiceRiga]',
                operator: 'contains',
                value: idRigaSchedaTg
            }
        ],
    });

    useEffect(() => {

    }, []);

    const deleteRigaStd = useDelete();

    const handleDelete = (e: any) => {
            console.log(e);
            setIdRigaSchedaTg(e.codiceRigaStd);
            setTimeout(() => {
                checkRigheStd().then( (res: any) => {
                    console.log(res);
                    if (res.data && res.data.data && res.data.data.length > 0) {
                        message.warning('Non puoi Eliminare il Record in quanto presente su Schede Taglie')
                    } else {
                        console.log('Eliminazione riga: ', e.id);
                        deleteRigaStd.mutateAsync({
                            resource: 'righe-std-scheda-taglies',
                            id: e.id
                        }).then((e: any) => {
                            tableQueryResult.refetch();
                        });
                    }
                })
            }, 600)

    }

    const cancel = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        message.error('Operazione Annullata');
    };

    const {edit } = useNavigation();
    const handleEdit = (e: any) => {
        // console.log(e);
        setIdRigaSchedaTg(e.codiceRigaStd);
        setVersioneRiga(e.versione);
        edit('righe-std-scheda-taglies', e.id);
        // setTimeout(() => {
        //     refetch().then( (res: any) => {
        //         console.log(res);
        //         if (!res.data && res.data.data && res.data.data.length > 0) {
        //             message.warning('Non puoi Modificare il Record in quanto presente su Schede Taglie')
        //         } else {
        //             edit('righe-std-scheda-taglies', e.id);
        //         }
        //     })
        // }, 600)

    }

    const createRigheStdSchedeTaglie = useCreate<IRigheStandardSchedeTaglie>();
    // const createMisureRigheStd = useCreateMany<IMisureRigheStd>();

    const clone = (record: any) => {
        createRigheStdSchedeTaglie.mutateAsync({
            resource: 'clonaRigheStd',
            values: {id: record.id}
        }).then((res: any) => {
            console.log(res);
            edit('righe-std-scheda-taglies', res.data.data);
        })
    }


    // const [loadingDet, setLoadingDet] = useState(false);
    const [idRiga, setIdRiga] = useState();
    const { refetch: listRefetch } = useOne<IRigheStandardSchedeTaglie>({
        meta: {
            populate: "rifMisureRigheStds, rifMisureRigheStds.rifTaglie",
        },
        queryOptions: {
            enabled: false
        },
        id: idRiga
    });
    const view = (id: any) => {
        // setLoadingDet(true);
        setIdRiga(id);
        const columns: any = [];
        let dataDet: any = [{}];
        setTimeout(() => {
            listRefetch().then((res: any) => {
                if (res && res.data && res.data && res.data.data) {
                    for (const m of res.data.data.rifMisureRigheStds.sort((a: any,b: any) => a.rifTaglie.ordTg - b.rifTaglie.ordTg)) {
                        dataDet[0][m.rifTaglie.descTg]= m.misura;
                        columns.push({
                            title: m.rifTaglie.descTg,
                            dataIndex: m.rifTaglie.descTg,
                            key: m.rifTaglie.descTg,
                            align: 'center',
                            width: 40,
                            render: (value: any, record: any) => (
                                value
                            ),
                        },)
                    }
                    // setLoadingDet(false);
                    Modal.info({
                        width: '70%',
                        title: res.data.data.codiceRigaStd,
                        content:
                            <Table
                                pagination={false}
                                loading={dataDet.length === 0}
                                dataSource={dataDet} columns={columns}>

                            </Table>,
                        footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                                <OkBtn />
                            </>
                        ),
                    });
                }
            });
        }, 100)
    }

    const checkRighe = useCreate();
    const findSchede = (record: any) => {
        console.log(record);
            // CONTROLLO ESISTENZA RIGA SU ALTRE SCHEDE
            checkRighe.mutateAsync({
                resource: 'findRigheSchede',
                successNotification: false,
                values: {
                    codiceRiga: record.codiceRigaStd,
                    versione: record.versione
                }
            }).then((res: any) => {
                console.log(res);
                if (res && res.data && res.data.length > 0) {
                    Modal.info({
                        width: '70%',
                        title: 'Elenco Schede Taglie del Codice Riga Selezionato',
                        content:
                            <Table
                                rowClassName={(record: any, index) => record.statoScheda === 'Aperta' ? 'green' :  ''}
                                pagination={false}
                                loading={res.data.length === 0}
                                onRow={(record) => ({
                                    onClick: (event: any) => {
                                        if (event.target.nodeName === "TD") {
                                            console.log(record);
                                            window.open(FRONT_URL + "/schede-taglies/edit/" + record.idScheda, "_blank")
                                        }
                                    },
                                })}
                                key={'key'}
                                size={'small'}
                                dataSource={res.data}>
                                <Table.Column
                                    width={100}
                                    title={'Nome Scheda'}
                                    dataIndex={'nomeScheda'}
                                    key={'nomeScheda'}
                                />
                                <Table.Column
                                    width={100}
                                    ellipsis={true}
                                    title={'Stato Scheda'}
                                    dataIndex={'statoScheda'}
                                    key={'statoScheda'}
                                />
                                <Table.Column
                                    width={300}
                                    ellipsis={true}
                                    title={'Descrizione Scheda'}
                                    dataIndex={'descScheda'}
                                    key={'descScheda'}
                                />
                                <Table.Column
                                    width={300}
                                    ellipsis={true}
                                    title={'Misure Righe'}
                                    dataIndex={'misure'}
                                    key={'misure'}
                                />
                                <Table.Column
                                    width={150}
                                    title={'Codice Riga'}
                                    dataIndex={'codRiga'}
                                    key={'codRiga'}
                                />
                            </Table>,
                        cancelText: 'Chiudi',
                    });
                }
            })
    }

    return (
        <List title={'Taglie'} breadcrumb={false} headerButtons={
            <>
                <Form {...searchFormProps} layout="inline">
                    <Form.Item name="description">
                        <Input placeholder="Search..." allowClear={true} onChange={searchFormProps.form?.submit}/>
                    </Form.Item>
                    <CreateButton />
                </Form>
            </>
        }>
            <Table {...tableProps}
                   scroll={{y: window.innerHeight - 150}}
                   sticky={true}
                   size={"small"}
                   rowKey="id">
                <Table.Column
                    dataIndex="codiceRigaStd"
                    title="Codice"
                    width={180}
                    defaultSortOrder={'descend'}
                    sorter={(a: any, b: any) => {
                        // console.log(a.letteraRiga.length,b.letteraRiga.length);
                        return a.codiceRigaStd.localeCompare(b.codiceRigaStd)
                    }}
                    render={(value, record) => <TagField color={record.isUltimaVersione ? 'green': 'red'} value={value} />}
                />
                <Table.Column
                    dataIndex="versione"
                    title="Versione"
                    width={80}
                    sorter={(a: any, b: any) => {
                        // console.log(a.letteraRiga.length,b.letteraRiga.length);
                        return a.versione - b.versione
                    }}
                />
                <Table.Column
                    dataIndex="descRigaStd"
                    title="Descrizione"
                    width={850}
                    sorter={(a: any, b: any) => {
                        // console.log(a.letteraRiga.length,b.letteraRiga.length);
                        return a.descRigaStd.localeCompare(b.descRigaStd)
                    }}
                />
                <Table.Column
                    dataIndex="rifGruppoTg"
                    title="Gruppo Taglie"
                    width={120}
                    // sorter={(a: any, b: any) => {
                    //     // console.log(a.rifGruppoTg);
                    //     return a.rifGruppoTg?.descGruppoTg.localeCompare(b.rifGruppoTg?.descGruppoTg)
                    // }}
                    render={(value, record: any) => {
                        return value
                    }}
                />
                <Table.Column<IRigheStandardSchedeTaglie>
                    title="Actions"
                    width={120}
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <Button
                                    size="small"
                                    icon={<Icons.EditOutlined />}
                                    onClick={() => handleEdit(record)}
                                />
                                <Button onClick={() => view(record.id)}
                                        size={'small'}
                                        icon={<Icons.SearchOutlined />}
                                ></Button>
                                <Button onClick={() => clone(record)}
                                        size={'small'}
                                        icon={<Icons.CopyOutlined />}
                                        ></Button>
                                <Button onClick={() => findSchede(record)}
                                        size={'small'}
                                        icon={<Icons.FileSearchOutlined />}
                                ></Button>
                                <Popconfirm
                                    title="Attenzione!"
                                    description="Sei Sicuro di Voler Eliminare la Riga?"
                                    onConfirm={() => handleDelete(record)}
                                    onCancel={() => cancel}
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <Button
                                        type={'primary'}
                                        style={{
                                            backgroundColor: 'red',
                                            border: 'none'
                                        }}
                                        size="small"
                                        icon={<Icons.DeleteOutlined />}
                                    />
                                </Popconfirm>

                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
