import { useForm, Edit } from "@refinedev/antd";
import { Form, Input, Table } from "antd";
import {IStagioni} from 'interfaces';
import React, {useEffect} from 'react';

interface sedi {
    label: string;
    value: number;
}
export const StagioniEdit: React.FC = () => {
    const { formProps, saveButtonProps, formLoading } = useForm<IStagioni>({
        meta: {
            populate: "*",
        },
    });


    useEffect( () => {

    }, [formLoading])


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Nome Stagione"
                    name="nomeStagione"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Riferimento Taglie"
                    name="rifSchedaTaglie"
                >
                    <Table />
                </Form.Item>
            </Form>
        </Edit>
    );
};
