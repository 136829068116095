import { Create, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Switch,
    Table,
} from "antd";

import {IGruppoTaglie, IMisureRigheStd, IRigheStandardSchedeTaglie, ITaglie} from 'interfaces';
import React, {useEffect, useState} from 'react';
import { useCreateMany, useList } from "@refinedev/core";

export const RigheStandardSchedaTaglieCreate = () => {
    const {formProps, saveButtonProps, onFinish} = useForm<IRigheStandardSchedeTaglie>();
    const createMisureRigheStd = useCreateMany<IMisureRigheStd>();
    const { data: getRigheList } = useList<IRigheStandardSchedeTaglie>({
        resource: 'righe-std-scheda-taglies',

        pagination: {
            pageSize: 9999999,
            mode: 'server'
        }
    });

    const handleSubmit = (e: any) => {
        console.log(e);
        console.log(righeM[0]);
        // CONTROLLO CODICE ESISTENTE
        const checkIfExist: any = getRigheList?.data.findIndex((x: any) => x.codiceRigaStd === e.codiceRigaStd);
        console.log(checkIfExist);
        if (checkIfExist > -1) {
            message.warning('Attenzione Codice Esistente');
        } else {
            e.descRigaStd = e.descRigaStd.toUpperCase();
            e.codiceRigaStd = e.codiceRigaStd.toUpperCase();
            onFinish(e).then( (res: any)  => {
                console.log(res);
                console.log(taglie);
                delete righeM[0].Actions;
                console.log(righeM[0]);
                const misureRigheStd = Object.keys(righeM[0]).map(key => {
                    const t = taglie.find((x: any) => x.label === key)?.value;
                    console.log(t);
                    return {
                        rifRigheStdSchedaTg: res.data.data.id,
                        rifTaglie: taglie.find((x: any) => x.label === key)?.value,
                        misura: righeM[0][key],
                        min: Number(Number(righeM[0][key]) - Number(res.data.data.attributes.tolleranzaRigaStd)).toFixed(1),
                        max: Number(Number(righeM[0][key]) + Number(res.data.data.attributes.tolleranzaRigaStd)).toFixed(1)
                    }
                })
                console.log(misureRigheStd);
                createMisureRigheStd.mutate({
                    resource: 'misure-righe-stds',
                    values: misureRigheStd
                })
            });
        }
    }

    const {selectProps: gruppoTaglieSelectProps, queryResult} = useSelect<IGruppoTaglie>({
        pagination: {
            pageSize: 100
        },

        resource: 'gruppo-taglies',

        meta: {
            populate: '*'
        },

        optionValue: 'id',
        optionLabel: 'descGruppoTg',

        sorters: [
            {
                field: 'rifTg][ordTg]',
                order: 'asc'
            }
        ]
    });


    const [taglie, setTaglie] = useState<any>();
    const [showMisure, setShowMisure] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [righeM, setRigheM] = useState<any>([]);

    const handleChangeTg = (key: any, value: any, index: number, data: any) => {
        console.log(key, value, index, data, righeM);
        data[key] = value;
        message.info('Taglia Modificata Correttamente');
    }
    const getTaglie = (data: any) => {
        setRigheM([]);
        const tg = queryResult.data?.data?.find((item => item.id === data));
        const foundTg = tg?.rifTg?.map(item => ({
            label: item.descTg,
            value: item.id,
        }));
        const col: any = tg?.rifTg?.map( item => ({
            title: item.descTg,
            dataIndex: item.descTg,
            key: item.descTg,
            render: (value: any, record: any, index: number) => {
                console.log(record)
                return (
                    <Input defaultValue={value} onPressEnter={(e: any) => handleChangeTg(item.descTg, e.target.value, index, record)} />
                )
            }
        ,
        }))
        col.push({
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (v: any, record: any) => {
                return <Button onClick={deleteRow} size={'small'} style={{ color: 'red'}} icon={<Icons.DeleteOutlined />}  />
            }
        })
        setTaglie(foundTg);
        setColumns(col);
        setTimeout(() => {
            const isTagliaBase: any = tg?.rifTg.find((t: any) => t.isUnaTgBase);
            console.log(isTagliaBase);
            formProps.form?.setFieldsValue({'rifTgBase': isTagliaBase.id})
        }, 500)

    }

    const deleteRow = () => {
        setRigheM((current: any) => [...[]])
    }

    const getMisure = () => {
        if (formProps.form?.getFieldValue('rifTgBase')) {
            setRigheM((current: any) => [...[]]);
            setTimeout(() => {
                if (formProps.form?.getFieldValue('misuraTgBase')) {
                    const misuraTgBase = Number(formProps.form?.getFieldValue('misuraTgBase'));
                    const scalaTg = Number(formProps.form?.getFieldValue('scalaTg'));
                    const tagliaBase = taglie.find((x: any) => x.value === formProps.form?.getFieldValue('rifTgBase'))['label'];

                    // console.log(misuraTgBase, scalaTg)
                    setShowMisure(true);
                    let v = scalaTg;
                    const indexBase = columns.findIndex((x: any) => x.key === tagliaBase);

                    const obj = Object.fromEntries(
                        columns.map((obj: any, i: number) => {
                            return [obj.title, (indexBase === i ? misuraTgBase
                                : i < indexBase ? Number(misuraTgBase - ((indexBase - i) * v)).toFixed(1)
                                    : i > indexBase ? Number(misuraTgBase + ((i - indexBase) * v)).toFixed(1) : 0)]
                        })
                    );
                    // console.log(obj);
                    setRigheM((current: any) => [...current, obj])
                }
            }, 500)
        } else {
            setRigheM((current: any) => [...[]]);
            // console.log(columns);
            setTimeout(() => {
                setShowMisure(true);
                console.log('Genero righe vuote');
                const obj = Object.fromEntries(
                    columns.map((obj: any, i: number) => {
                        return [obj.title, 0]
                    })
                );
                setRigheM((current: any) => [...current, obj])
            }, 500);

        }

    }

    function increment_alphanumeric_str(str: any){
        var numeric = str.match(/\d+$/)[0];
        var prefix = str.split(numeric)[0];

        function increment_string_num(str: any){
            var inc = String(parseInt(str)+1);
            return str.slice(0, str.length-inc.length)+inc;
        }

        return prefix+increment_string_num(numeric);
    }

    useEffect(() => {
        console.log(getRigheList?.data);
        if (getRigheList && getRigheList.data && getRigheList.data.length > 0) {
            const maxCodice: any = getRigheList?.data.reduce((prev, current) => (prev.codiceRigaStd > current.codiceRigaStd) ? prev : current)
            // console.log(maxCodice.codiceRigaStd);
            const codiceRiga = increment_alphanumeric_str(maxCodice.codiceRigaStd.split('/')[0]);
            const codiceRiga2 = maxCodice.codiceRigaStd.split('/')[1];
            // console.log(codiceRiga, codiceRiga2);
            formProps.form?.setFieldsValue({'codiceRigaStd': codiceRiga + '/' + codiceRiga2});
            formProps.form?.setFieldsValue({'versione': 1});
            formProps.form?.setFieldsValue({'codiceBaseRigaStd': codiceRiga});
        }

    })

    return (
        <Create saveButtonProps={saveButtonProps} breadcrumb={false}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Codice"
                            name="codiceRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Ver."
                            name="versione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Codice Base"
                            name="codiceBaseRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Descrizione"
                            name="descRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{textTransform: 'uppercase'}} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Tolleranza"
                            name="tolleranzaRigaStd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'} step="0.1"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Tolleranza %"
                            name="isTolleranzaPercentualeRigaStd"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Gruppo Taglie"
                            name="rifGruppoTg"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...gruppoTaglieSelectProps} onSelect={(e) => getTaglie(e)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Misura Tg Base"
                            name="misuraTgBase"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'1'}
                                         style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Scala Tg"
                            name="scalaTg"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'}
                                         step="0.1"
                                         style={{width: '100%'}} onInput={getMisure}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Taglia Base"
                            name="rifTgBase"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select options={taglie} onSelect={getMisure}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Banda"
                            name="isBanda"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Taglio Riferimento"
                            name="isTaglioRif"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Posizione Taglia Base"
                            name="posizioneTgBase"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'}
                                         step="0.1"
                                         style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Larghezza Taglia Base"
                            name="lBandaTgBase"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber placeholder={'0.1'}
                                         step="0.1"
                                         style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label=" "
                            name="gr"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Button type={'primary'} style={
                                {width: '100%'}
                            } onClick={getMisure}>Genera Riga</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <hr/>
                <div hidden={!showMisure}>
                    <Table pagination={false} dataSource={righeM} columns={columns}>
                    </Table>
                </div>

            </Form>
        </Create>
    );
};
