import { useForm, Edit } from "@refinedev/antd";
import { Form, Input, Table, Switch } from "antd";
import {IStatiSchedeTaglie} from 'interfaces';
import React from 'react';

export const StatiSchedeTaglieEdit: React.FC = () => {
    const { formProps, saveButtonProps, formLoading } = useForm<IStatiSchedeTaglie>({
        resource: 'stati-schede-taglies',
        meta: {
            populate: "*",
        },
    });


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Stato Scheda Taglia"
                    name="descStatoSchedaTaglie"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Aperta"
                    name="isAperta"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Da Controllare"
                    name="isDaControllare"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Segnalata"
                    name="isSegnalata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Controllata"
                    name="isControllata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Bloccata"
                    name="isBloccata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Pubblicata"
                    name="isPubblicata"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Default"
                    name="isDefault"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label="Riferimento Taglie"*/}
                {/*    name="rifSchedaTaglie"*/}
                {/*>*/}
                {/*    <Table />*/}
                {/*</Form.Item>*/}
            </Form>
        </Edit>
    );
};
